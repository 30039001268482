// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `App-header {
    background-color: #282c34;
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 5vh;
    text-align: center;
    padding: 12px;
    margin-top: 1vh;
   }

   wrapper-class {
    padding: 1rem;
    border: 1px solid rgb(165, 48, 48);
  }
  .editor-class {
    background-color:rgb(255, 255, 255);
    padding: 1rem;
    height: 50vh;
    margin-top: 3vh;
    border: 1px solid rgb(125, 112, 112);
  }
  .toolbar-class {
    margin-top: 1rem;
    border: 2px solid #C26DBC;
  }`, "",{"version":3,"sources":["webpack://./src/css/editor.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,eAAe;GAChB;;GAEA;IACC,aAAa;IACb,kCAAkC;EACpC;EACA;IACE,mCAAmC;IACnC,aAAa;IACb,YAAY;IACZ,eAAe;IACf,oCAAoC;EACtC;EACA;IACE,gBAAgB;IAChB,yBAAyB;EAC3B","sourcesContent":["App-header {\n    background-color: #282c34;\n    min-height: 5vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: white;\n    margin-bottom: 5vh;\n    text-align: center;\n    padding: 12px;\n    margin-top: 1vh;\n   }\n\n   wrapper-class {\n    padding: 1rem;\n    border: 1px solid rgb(165, 48, 48);\n  }\n  .editor-class {\n    background-color:rgb(255, 255, 255);\n    padding: 1rem;\n    height: 50vh;\n    margin-top: 3vh;\n    border: 1px solid rgb(125, 112, 112);\n  }\n  .toolbar-class {\n    margin-top: 1rem;\n    border: 2px solid #C26DBC;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

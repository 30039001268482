import {
  CloudUpload,
  CloudUploadOutlined,
  FileUpload,
  FileUploadOutlined,
  Send,
  Upload,
} from "@mui/icons-material";
import { Box, Typography, Modal, Button } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadLeeds } from "app/utils/api-urls/AdminAPiUrls";
import { outboundCampaign } from "app/utils/axiosRequest";
import React, { useState } from "react";

const UploadFileModal = ({
  openmodal,
  handleCloseUploadFileModal,
  uploadLeadsData,
  refetcRegisteredClients,
}) => {
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file.name);
  };

  const queryClient = useQueryClient();

  const handleUploadButtonClick = () => {
    if (!selectedFile) {
      alert("Please select a file before uploading.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("campaignId", uploadLeadsData._id.toString());
    uploadLeedsMutate(formData);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const {
    isLoading: uploadLeedsLoading,
    error: uploadLeedsError,
    mutate: uploadLeedsMutate,
  } = useMutation(
    ["Upload-Leeds"],
    (formData) => outboundCampaign.post(uploadLeeds.upload_leeds, formData),
    {
      onSuccess: () => {
        handleCloseUploadFileModal();
        queryClient.refetchQueries(["registered Clients", uploadLeadsData._id]);
        refetcRegisteredClients();
      },
    }
  );

  return (
    <Modal keepMounted open={openmodal} onClose={handleCloseUploadFileModal}>
      <Box sx={style}>
        <Box display="flex" flexDirection="column" gap="3rem">
          <Typography id="keep-mounted-modal-title" variant="h6">
            Import CSV file !
          </Typography>
          <Button
            variant="outlined"
            size="small"
            component="label"
            startIcon={<FileUploadOutlined />}
          >
            <input
              hidden
              accept=".csv"
              multiple
              type="file"
              onChange={handleFileSelection}
            />

            {selectedFileName ? selectedFileName : "Import CSV File"}
          </Button>
        </Box>
        <Box display="flex" marginTop="2rem" justifyContent="flex-end">
          <Button
            variant="contained"
            size="medium"
            onClick={handleUploadButtonClick}
            endIcon={<CloudUpload sx={{ fontSize: "25px" }} />}
          >
            Upload
          </Button>
        </Box>

        <br />
      </Box>
    </Modal>
  );
};

export default UploadFileModal;

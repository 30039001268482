import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { instance } from "app/utils/axiosRequest";
import { getEmailTemplate } from "app/utils/api-urls/AdminAPiUrls";
import { useMutation } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";

const AddTemplateModalComponent = ({
  open,
  handleCloseModal,
  refetch,
  tabValue,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    message: "",
    type: tabValue === 0 ? "PENDING" : "SOLVED",
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const {
    mutate: postEmailTemplate,
    isLoading,
    isRefetching,
  } = useMutation(
    ["post-template"],
    () => instance.post(getEmailTemplate.get_email_template, formData),
    {
      onSuccess: () => {
        refetch();
        handleCloseModal();
        setSnackBar({
          open: true,
          severity: "success",
          message: "Template added successfully!",
        });
        setFormData({ title: "", message: "" });
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error adding template!",
        });
      },
    }
  );

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      type: tabValue === 0 ? "PENDING" : "SOLVED",
    }));
  }, [tabValue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  const handleSubmit = () => {
    postEmailTemplate();
  };

  return (
    <>
      <FullpageLoader open={isLoading || isRefetching} />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {tabValue === 0
              ? "Add message for pending status"
              : "Add message for solved status"}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            autoFocus
            value={formData.title}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="message"
            label="Message"
            id="message"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              disabled={
                !formData.title.trim() ||
                !formData.message.trim() ||
                isLoading ||
                isRefetching
              }
            >
              {isLoading || isRefetching ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddTemplateModalComponent;

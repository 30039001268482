// // serviceWorker.js
// importScripts("./sw-utils.js");

// // Set the cache name and version number
// const CACHE_NAME = "my-pwa-cache-v1";

// // Set the files to cache
// const urlsToCache = [
//   "/",

// ];

// // Install the service worker
// self.addEventListener("install", (event) => {
//   event.waitUntil(
//     caches.open(CACHE_NAME).then((cache) => {
//       return cache.addAll(urlsToCache);
//     })
//   );
// });

// // Fetch files from the cache
// self.addEventListener("fetch", (event) => {
//   event.respondWith(
//     caches.match(event.request).then((response) => {
//       // Return the cached file, or fetch a new one if not in the cache
//       return response || fetch(event.request);
//     })
//   );
// });

// // Update the cache with new files
// self.addEventListener("activate", (event) => {
//   event.waitUntil(
//     caches.keys().then((cacheNames) => {
//       return Promise.all(
//         cacheNames.map((cacheName) => {
//           if (cacheName !== CACHE_NAME) {
//             return caches.delete(cacheName);
//           }
//         })
//       );
//     })
//   );
// });

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}

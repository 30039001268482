import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Button } from "@mui/material";
import CommonReportFilter from "app/pages/AdminDashboard/CommonReportFilter";
import dayjs from "dayjs";
import { Download } from "@mui/icons-material";
import { instance } from "app/utils/axiosRequest";
import { useQuery } from "@tanstack/react-query";
import LogReportTable from "./LogReportTable";
const LogReport = () => {
  const [filterData, setFilterData] = useState({
    extension: "all",
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    page: 1,
  });

  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    ["admin-Log-Report"],
    () => instance.post("admin/log-report", filterData)
  );

  useEffect(() => {
    data && console.log(data, "logreport");
  }, [data]);

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleClear = () => {
    setFilterData({
      ...filterData,
      extension: "",
      from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
    });
  };

  const handleFilter = () => {
    refetch();
  };

  // /pagination left click
  const handleLeftClick = () => {
    setFilterData({
      ...filterData,
      page: --filterData.page,
    });
    refetch();
  };

  //pagination right click
  const handleRightClick = () => {
    setFilterData({
      ...filterData,
      page: ++filterData.page,
    });
    refetch();
  };

  return (
    <Box display="flex" gap="10px" width="100%" marginTop="1em">
      <Card sx={{ width: "25%" }}>
        <CardContent>
          <CommonReportFilter
            reportType="LogDetails"
            filterData={filterData}
            handleFromDate={handleFromDate}
            handleToDate={handleToDate}
            handleChange={handleChange}
            handleClear={handleClear}
            handleFilter={handleFilter}
            isLoading={isRefetching}
          />
        </CardContent>
      </Card>

      <Box width="75%">
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="flex-end" marginBottom="10px">
              <Button variant="contained" startIcon={<Download />}>
                Download
              </Button>
            </Box>
            <LogReportTable
              isLoading={isLoading}
              tableData={data?.data}
              handleLeftClick={handleLeftClick}
              handleRightClick={handleRightClick}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default LogReport;

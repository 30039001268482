import React from "react";
import { useLocation } from "react-router-dom";
import { convertDashedToReadable } from "app/utils";
import { Link } from "react-router-dom";

// interface NavProps{
//     title:string,
//     onClick?:()=>{},
//     current?:boolean
// }

/**
 * A common Header UI for Broker views
 * @param {string} title - title of the header
 * @param {array} nav - NavProps Array
 */
const Header = ({ title, icon }) => {
  const location = useLocation();
  // const history = useHistory();
  let nav = [];
  // let title = "Dashboard";

  const arr = location.pathname.split("/");

  let path = "/app";
  arr.forEach((e, i) => {
    if (e === "app") {
    } else if (e === "") {
    } else {
      const isLastItem = i === arr.length - 1;
      const repairedString = convertDashedToReadable(e);
      path += "/" + e;
      nav.push({
        title: repairedString,
        path,
        onClick: (e) => {
          e.preventDefault();
        },
        current: isLastItem,
      }); 
      // if (isLastItem) {
      //   title = repairedString;
      // }
    }
  });

  return (
    <div className="page-header pb-0">
      <h4 className="page-title font-weight-bold">
        <span className="page-title-icon bg-orange text-white mr-2">
          <i className={`${icon}`}></i>
        </span>
        {title}
      </h4>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {nav.map((e, i) => {
            if (e.current)
              return (
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  key={i}
                >
                  {e.title}
                </li>
              );
            else
              return (
                <li className="breadcrumb-item" key={i}>
                  <Link to={e.path}>{e.title}</Link>
                </li>
              );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default Header;

// import axiosRequest from "./axiosRequest";
import { addMessage } from "./message";
import { BSToAD, ADToBS } from "bikram-sambat-js";

/**
 * Converts Array to object based on "prop" property
 * convertArrayToObject=([{id:1,name:"prajwal",{id:2,name:"pradhan"}}],id) ==> {1:{id:1,name:"prajwal"},2:{id:2:name:"pradhan"}}
 * @param {array} data - the data that is to be converted to object
 * @param {string} prop  - the property of each data value that is used to convert array to object
 */
const convertArrayToObject = (data, prop) => {
  return data.reduce((obj, curr) => {
    return { ...obj, [curr[prop]]: { ...curr } };
  }, {});
};

/**
 * @returns {string} - returns Today's Date in format YYYY-MM-DD
 */
const getTodaysDate = () => {
  const date = new Date();
  return dateFormatter(date);
};

/**
 *
 * @param {dateString} dateStr  Date String
 * @param {string} - returns in this YYYY-MM-DD
 */
function dateFormatter(dateStr) {
  try {
    const date = dateStr ? new Date(dateStr) : new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, 0);
    const day = String(date.getDate()).padStart(2, 0);
    return year + "-" + month + "-" + day;
  } catch (err) {
    return "-";
  }
}
/**
 * @param {object} data - object with empty fields
 * @returns {object} - returns object with filled fields
 */
const removeEmptyFields = (data) => {
  const temp = { ...data };

  for (var key in data) {
    if (
      temp[key] === "" ||
      temp[key] === null ||
      temp[key] === undefined ||
      Object.is(NaN, temp[key])
    )
      delete temp[key];
  }
  return { ...temp };
};
/**
 *
 * @param {number} n - The number to be converted
 * @param {number} x - The number of points that need to appear after decimal
 */
function toFloat(n, x) {
  return parseFloat(n).toFixed(x);
}
/**
 * //TODO please Check if there are any better implementation
 * 123434.00 ==>1,23,434.00
 * @param {string} num - The string number where comma is to be added
 * @return - Returns string
 */
function addCommas(num) {
  let splitted = num.split(".");

  if (splitted[0].length > 3) {
    const onlyBeginningParts = splitted[0].slice(0, splitted[0].length - 3);
    const lastParts = splitted[0].slice(splitted[0].length - 3);
    splitted[0] =
      onlyBeginningParts.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      lastParts;

    return splitted.join(".");
  } else return num;
}
/**
 *
 * @param {number} n  The number to be converted
 * @return {number} - Adding only two trailin number/ zero after n
 */
function toFloatAmount(n) {
  return addCommas(toFloat(n, 2));
}

/**
 *
 * @param {number} n  The number to be converted
 * @return {number} - Adding only four trailin number/ zero after n
 */
function toFloatPercent(n) {
  return toFloat(n, 4);
}

/**
 * maintain-privilege ==> Maintain privilege
 * import-sell-/-buy-data ==> Import sell/buy data
 * @param {string} str - dashed string;
 * @returns {string} - readable Strings
 */
const convertDashedToReadable = (str) => {
  const remove_dashes = str.replaceAll("-/-", "/").replaceAll("-", " ");
  const first_char_cap =
    remove_dashes.charAt(0).toUpperCase() + remove_dashes.slice(1);

  return first_char_cap;
};

/**
 * converts "1/22/2021 12:00:00 AM" = 2021-22-01
 * @param {string} str- "1/22/2021 12:00:00 AM" date in this formate
 */
const convertSlashedDateToDashedDate = (str) => {
  try {
    const date = new Date(str);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, 0);
    const day = String(date.getDate()).padStart(2, 0);
    return year + "-" + month + "-" + day;
  } catch (err) {
    return "-";
  }
};

/**
 * converts 2054-01-25 = 1997-05-07
 * @param{ string} bs- 2054-01-25
 */
const convertToAd = (bs) => {
  try {
    return BSToAD(bs);
  } catch (err) {
    return "-";
  }
};

/**
 * converts 1997-05-07 = 2054-01-25
 * @param {string} ad - 1997-05-07
 */
const convertToBs = (ad) => {
  try {
    return ADToBS(ad);
  } catch (err) {}
};

function truncateString(str, length = 50, ending = "...") {
  try {
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  } catch (err) {
    return "";
  }
}

/**
 *
 * @param {*} val - Value to check if its empty
 * @param {*} sign - custom empty sign
 * @returns
 */
const isEmpty = (val, sign) =>
  val === "" || val === null || val === undefined ? sign || "-" : val;

const isUserLogged = () => {
  const isLogged = localStorage.getItem("callCenterToken");
  if (
    isLogged === null ||
    isLogged === undefined ||
    isLogged === "" ||
    isLogged === "null"
  ) {
    return false;
  }
  return true;
};

/**
 *
 * @param {Aray} categories Array of category names
 * @returns
 */
const barChartOptionsHorizental = (categories) => {
  return {
    plotOptions: {
      bar: {
        // columnWidth: categories?.length === 1 ? "10%" : "50%",
        borderRadius: 4,
        horizontal: true,
        // distributed: true,
      },
    },
    chart: {
      type: "bar",
      height: 350,
    },
    // colors: ["#00bcd4", "#ff9800"],

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
    },

    xaxis: {
      type: "category",
      labels: {
        formatter: (val) => {
          return val;
        },
      },
      categories: categories,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
};

const barChartOptions = (categories) => {
  return {
    plotOptions: {
      bar: {
        columnWidth: categories?.length === 1 ? "10%" : "50%",
        // distributed: true,
      },
    },
    // colors: ["#00bcd4", "#ff9800"],
    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    yaxis: {
      title: {
        text: "Minutes/Counts",
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
    },

    xaxis: {
      type: "category",

      labels: {
        formatter: (val) => {
          return val;
        },
      },
      categories: categories,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
};

/** generate LTE/GTE date */
/**
 *
 * @param {Date} date - Date
 * @param {Array} keys - Arrays of Keys date[gte]/date[lte]
 * @returns
 */
const formateAsLTEGTE = (date = new Date(), keys) => {
  if (date === null) {
    return {};
  }
  return {
    [keys[0]]: new Date(date)?.setHours(0, 0, 0, 0),
    [keys[1]]: new Date(date)?.setHours(23, 59, 59, 999),
  };
};

/**
 *
 * @param {String} str - string  to capatliaze
 * @returns
 */
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * check if url path is resetPassword or not
 * @returns
 */
const isResetPasswordUrl = () =>
  window.location.pathname.includes("resetPassword");

const getUserId = () => JSON.parse(localStorage.getItem("callCenterUser"))?._id;
export {
  // axiosRequest,
  convertArrayToObject,
  getTodaysDate,
  dateFormatter,
  removeEmptyFields,
  toFloat,
  toFloatAmount,
  toFloatPercent,
  convertDashedToReadable,
  convertToAd,
  convertToBs,
  convertSlashedDateToDashedDate,
  addMessage,
  truncateString,
  isEmpty,
  isUserLogged,
  barChartOptions,
  formateAsLTEGTE,
  capitalize,
  isResetPasswordUrl,
  barChartOptionsHorizental,
  getUserId,
};

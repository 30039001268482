import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import { breakCategory } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import React, { useState } from "react";
const AddBeakCategory = ({ open, handleCloseModal, refetch }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const {
    mutate: postBreakMutate,
    isLoading,
    isRefetching,
  } = useMutation(
    ["post-template"],
    () => instance.post(breakCategory.break_category, formData),
    {
      onSuccess: () => {
        refetch();
        handleCloseModal();
        setSnackBar({
          open: true,
          severity: "success",
          message: "Break added successfully!",
        });
        setFormData({ name: "", description: "" });
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error adding Break Category!",
        });
      },
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  const handleSubmit = () => {
    postBreakMutate();
  };
  return (
    <>
      <FullpageLoader open={isLoading || isRefetching} />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Break Category
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="name"
            name="name"
            autoFocus
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="description"
            label="description"
            id="description"
            multiline
            rows={4}
            value={formData.description}
            onChange={handleChange}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              disabled={!formData.name.trim() || isLoading || isRefetching}
            >
              {isLoading || isRefetching ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddBeakCategory;

import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  useHistory,
} from "react-router-dom";
import "../css/phone.css";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { ProtectedApp } from "./ProtectedRoutes";
import { useDispatch } from "react-redux";
import CommonLayout from "./shared/common";
import { withTranslation } from "react-i18next";
import Login from "./pages/auth/Login";
// import UpdatePassword from "app/pages/auth/forgotPassword/updateForgotPassword";
import { isResetPasswordUrl, isUserLogged } from "./utils";
import "placeholder-loading/dist/css/placeholder-loading.min.css";
import socketIOClient from "socket.io-client";
import { connect } from "react-redux";
import { currentUuid } from "./store/ticketUid/TicketUidAction";
import { socketURL } from "./utils/axiosRequest";
import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Send } from "@material-ui/icons";
import { Chat } from "@mui/icons-material";
import eventEmitter from "./event-emitter/eventEmitter";

const userExtension = JSON.parse(localStorage.getItem("callCenterUser"));

const localStorageRole = localStorage.getItem("role");

const CallIndicator = () => {
  const dispatch = useDispatch();
  const [callState, setCallState] = React.useState(0);
  dispatch({ type: "EMPTY_CLIENT_DETAILS" });
  const [callDrawerActive, setcallDrawerActive] = React.useState(false);
  const [dialerOpen, setDialerOpen] = React.useState(false);

  useEffect(() => {
    // This function is called whenever the "callClicked" event is emitted.
    const handleCallClick = () => {
      setcallDrawerActive(true); // Set the call drawer to active.
      handleCallDrawer(); // Handle the logic associated with the call drawer (e.g., open/close).
    };

    // Adding an event listener to listen for the "callClicked" event.
    // When this event is emitted, the `handleCallClick` function will be executed.
    eventEmitter.on("callClicked", handleCallClick);

    // Cleanup function:
    // This is executed when the component is unmounted.
    // It's used to remove the event listener to prevent memory leaks.
    return () => {
      eventEmitter.removeListener("callClicked", handleCallClick);
    };
  }, []); // An empty dependency array ensures this useEffect runs only once (similar to componentDidMount).

  const showNotification = (phone) => {
    var options = {
      body: phone,
      dir: "ltr",
      icon: "https://cdn0.iconfinder.com/data/icons/phone-call-3/400/Calls-04-512.png",
    };
    var notification = new Notification("Incoming Call", options);
    notification.addEventListener("click", function () {
      window.focus();
    });

    setTimeout(notification.close.bind(notification), 150000);
  };

  const handleCallDrawer = () => {
    if (callDrawerActive === false) {
      document.getElementsByClassName("content-wrapper")[0].style.width = "78%";
      document.getElementsByClassName("dialer-container")[0].style.display =
        "block";
      document.getElementById("toField").focus();
      // window.innerWidth < 500 ? "100%" : "25%";
      // setTimeout(() => {
      //   setDialerOpen(true);
      // }, 500);
      // document.getElementsByClassName("navbar")[0].style.width = "78%";

      setcallDrawerActive(true);
    } else {
      document.getElementsByClassName("content-wrapper")[0].style.width =
        "100%";
      setDialerOpen(false);
      document.getElementsByClassName("dialer-container")[0].style.display =
        "none";
      document.getElementsByClassName("navbar")[0].style.width = "100%";
      setcallDrawerActive(false);
    }
  };

  // Socket for side dial pad drawer
  useEffect(() => {
    const accessToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(socketURL.liveUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    socket.on(`incoming@${userExtension}`, (data) => {
      data && handleCallDrawer();

      showNotification(data.phone);
    });
  }, []);

  const handleChatClick = () => {
    window.location.href = "/app/admin/chat-bot"; // Set the desired route
  };
  return (
    <>
      <div className="call-container-absolute">
        <Box display="flex" flexDirection="row" gap="1rem">
          <div
            onClick={handleCallDrawer}
            className={`call-container ${
              callState === 0
                ? "bg-orange"
                : callState === 1
                ? "bg-green pulse"
                : "bg-green call-container-calling"
            }`}
          >
            <i className="mdi mdi-face-agent"></i>
          </div>
          {/* {localStorageRole === "admin" && (
            <div>
              <IconButton
                onClick={handleChatClick}
                color="#F9F5F6"
                disableRipple
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "2px solid #0E2954", // Specify the desired color
                  backgroundColor: "#090580",
                }}
              >
                <Chat sx={{ color: "white" }} />
              </IconButton>
            </div>
          )} */}
        </Box>
      </div>
    </>
  );
};
const App = ({ setCurrentTicketUuid }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // Socket for ticket page redirection
  useEffect(() => {
    const accessToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(socketURL.liveUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    socket.on(`call-received@${userExtension}`, (data) => {
      if (data) {
        history.push("/app/ticket/create-ticket-new");
        setCurrentTicketUuid(data);
      }
    });
  }, []);

  React.useEffect(() => {
    if (!isUserLogged()) {
      if (isResetPasswordUrl()) {
      } else {
        history.push("/");
      }
    }
  }, [history, dispatch]);

  return (
    <div className="container-scroller">
      <Switch>
        <Route exact path="/" component={Login} />
        {/* <Route exact path="/resetPassword/:token" component={UpdatePassword} /> */}

        {/* Common Routes for all */}
        <ProtectedApp path="/app" component={CommonLayout} />

        <Redirect from="/" to="/login" />
      </Switch>
      <CallIndicator />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentTicketUuid: (current_uuid) => {
    dispatch(currentUuid(current_uuid));
  },
});

export default withTranslation()(
  withRouter(connect(null, mapDispatchToProps)(App))
);

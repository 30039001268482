import { combineReducers } from "redux";

import { loading, message } from "./commonReducers";
import { smallLoaderReducer } from "./loader/loaderReducer";

import authReducer from "./auth/authReducer";
import UsersReducer from "./../store/setup/users/UsersReducer";
import faqReducer from "./../store/faq/faqReducer";
import vipAccessReducer from "app/store/vip-access/vipAccessReducer";
import clientDetailsReducer from "app/store/clientDetails/clientDetailsReducer";
import taxonomyReducer from "app/store/taxonomy";
import permissionsReducer from "app/store/setup/permissions/permissionReducer";
import callReducer from "app/store/call/callReducer";
import primaryDescriptionReducer from "app/store/primary-description/primaryDescriptionReducer";
import { TicketUidReducer } from "./ticketUid/TicketUidReducer";

const allReducers = combineReducers({
  loading,
  message,
  authReducer,
  UsersReducer,
  faqReducer,
  smallLoaderReducer,
  vipAccessReducer,
  clientDetailsReducer,
  getTaxonomy: taxonomyReducer,
  permissionsReducer,
  callReducer,
  primaryDescriptionReducer,
  TicketUidReducer,
});

export default allReducers;

import { AttachFile, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";

const SendMessage = ({ handleSendMessage, handleMessageChange, message }) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "auto",
        }}
      >
        <TextField
          variant="outlined"
          label="Type a message"
          value={message.message}
          onChange={handleMessageChange}
          onKeyPress={handleKeyPress}
          fullWidth
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          endIcon={<Send />}
          onClick={handleSendMessage}
          sx={{ marginLeft: "10px" }}
        >
          Send
        </Button>
      </Box>
    </>
  );
};

export default SendMessage;

import Dialpad from "app/Dialpad";

import AgentReport from "app/pages/AgentReports";
import SupervisorReport from "app/pages/SupervisorReport/CallReport";
import SupervisorAgent from "app/pages/SupervisorReport/Supervisor-Agent/SupervisorAgent";

const SupervisorReportRoutes = [
  {
    path: "/app/report/supervisorreport",
    shortPath: "/report/supervisor-report",
    name: "Supervisor Call Report",
    component: SupervisorReport,
    icon: "mdi mdi-library",
  },
  {
    path: "/app/report/supervisor-Agent-Report",
    shortPath: "/report/supervisor-agent-report",
    name: "Supervisor Agent List",
    component: SupervisorAgent,
    icon: "mdi mdi-clock-outline",
  },

  // {
  //   path: "/app/report/dialpad",
  //   shortPath: "/report/dialpad",
  //   name: "Dial pad testing",
  //   component: Dialpad,
  //   icon: "mdi mdi-library",
  // },

  // {
  //   path: "/app/setup/roles",
  //   shortPath: "/setup/roles",
  //   name: "Roles",
  //   component: RoleList,
  //   icon: "mdi mdi-cube-unfolded",
  // },
  // {
  //   path: "/app/setup/permissions",
  //   shortPath: "/setup/permissions",
  //   name: "Permissions",
  //   component: Permissions,
  //   icon: "mdi mdi-account-multiple-check",
  // },
  // {
  //   path: "/app/setup/setup-permissions",
  //   shortPath: "/setup/setup-permissions",
  //   name: "Setup permissions",
  //   component: CreatePermissions,
  //   icon: "mdi mdi-account-multiple-check",
  // },
];

export default SupervisorReportRoutes;

import { FETCHED_PRIMARY_DESCRIPTION_LOGS } from "./primaryDescriptionActionTypes";

const init = {
  allPrimaryDescription: [],
};

const primaryDescriptionReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCHED_PRIMARY_DESCRIPTION_LOGS:
      return {
        ...state,
        allPrimaryDescription: payload?.primaryDecriptions,
      };
    default:
      return state;
  }
};

export default primaryDescriptionReducer;

import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { Box } from "@mui/material";

import { Bar } from "react-chartjs-2";

const HoldTimeTable = ({ holdtimeApiData }) => {
  // const options = {
  //   maintainAspectRatio: false,
  //   height: 2000,
  //   width: 800,
  // };

  const HoldTimeData = {
    labels: [
      "0-10",
      "10-20",
      "20-30",
      "30-40",
      "40-50",
      "50-60",
      "60-1:10",
      "1:10-1:20",
      "1:20-1:30",
      "1:30-1:40",
      "1:40-1:50",
      "1:50-2:00",
    ],
    datasets: [
      {
        label: "Hold Time in Seconds",
        data: holdtimeApiData,
        backgroundColor: [
          "#4D455D",
          "#E96479",
          "#6096B4",
          "#B99B6B",
          "#1F8A70",
          "#4E6C50",
          "#EF9A53",
          "#FF6464",
          "#609EA2",
          "#A31ACB",
          "#F99417",
          "#293462",
        ],
        borderWidth: 1,
      },
    ],
  };

  // const columns = [
  //   {
  //     field: "Queue",
  //     headerName: "Queue Status",
  //     width: 150,
  //   },

  //   {
  //     field: "createdAt",
  //     headerName: "Date",
  //     width: 150,
  //     //   renderCell: (params) => {
  //     //     return dayjs(params?.row?.createdAt).format("YYYY-MM-DD");
  //     //   },
  //   },
  //   {
  //     field: "AverageWaitingTime",
  //     headerName: "Average Waiting Time (sec)",
  //     width: 250,
  //   },

  //   {
  //     field: "LongestWaitTime",
  //     headerName: "Longest Wait Time (sec)",
  //     width: 250,
  //   },
  // ];

  // const rows = [
  //   {
  //     id: 1,
  //     Queue: "KYC",
  //     createdAt: "2022-11-15",
  //     AverageWaitingTime: "30sec",
  //     LongestWaitTime: "60sec",
  //   },
  //   {
  //       id: 2,
  //       Queue: "Payment",
  //       createdAt: "2022-11-15",
  //       AverageWaitingTime: "30sec",
  //       LongestWaitTime: "60sec",
  //     },
  //     {
  //       id: 3,
  //       Queue: "Billable",
  //       createdAt: "2022-11-15",
  //       AverageWaitingTime: "30sec",
  //       LongestWaitTime: "60sec",
  //     },
  //      {
  //       id: 4,
  //       Queue: "KYC",
  //       createdAt: "2022-11-15",
  //       AverageWaitingTime: "30sec",
  //       LongestWaitTime: "60sec",
  //     },
  //      {
  //       id: 5,
  //       Queue: "KYC",
  //       createdAt: "2022-11-15",
  //       AverageWaitingTime: "30sec",
  //       LongestWaitTime: "60sec",
  //     },

  // ];

  return (
    <Box>
      <Bar data={HoldTimeData} />
    </Box>
  );
};

export default HoldTimeTable;

// BulkEditModal.js
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  Box,
  Typography,
} from "@mui/material";
import NotificationModal from "app/pages/ticket/createNew/NotificationModal";
import {
  agentBulkTickets,
  agentGetTemplate,
} from "app/utils/api-urls/AgentApiUrls";
import {
  adminBulkTickets,
  getEmailTemplate,
} from "app/utils/api-urls/AdminAPiUrls";
import {
  supervisorbulkTickets,
  supervisorgetEmailTemplate,
} from "app/utils/api-urls/SupervisorAPiUrls";
import { useMutation, useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";

const BulkEditModal = ({ open, handleClose, selectedIds, refetch }) => {
  const userRole = localStorage.getItem("role");
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850, // Custom width
    maxHeight: 600, // Custom max height
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 1,
    borderRadius: 2,
  };
  const [sendNotification, setSendNotification] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationsMessageList, setNotificationsMessageList] = useState([]);
  const [notificationMesage, setNotificationMessage] = useState("");
  const [status, setStatus] = useState("pending");
  const [remarks, setRemarks] = useState("");

  const roleBasedUrls = {
    agent: {
      template: agentGetTemplate.agent_get_template,
      update: agentBulkTickets.agent_bulk_tickets,
    },
    admin: {
      template: getEmailTemplate.get_email_template,
      update: adminBulkTickets.admin_bulk_tickets,
    },
    supervisor: {
      template: supervisorgetEmailTemplate.supervisor_get_email_template,
      update: supervisorbulkTickets.supervisor_bulk_tickets,
    },
  };

  const handleSelectMessage = (message) => {
    setNotificationMessage(message);
  };
  const initialTemplateType = status === "closed" ? "SOLVED" : "PENDING";

  const [templateType, setTemplateType] = useState(initialTemplateType);

  const { template: templateUrl } =
    roleBasedUrls[userRole] || roleBasedUrls.agent;

  useEffect(() => {
    const mappedFollowUpStatus = status === "closed" ? "SOLVED" : "PENDING";
    setTemplateType(mappedFollowUpStatus);
  }, [status]);

  // Fetch email templates based on templateType
  const { data: getTemplate } = useQuery(["get-template", templateType], () => {
    return instance.get(`${templateUrl}?type=${templateType}`);
  });

  useEffect(() => {
    if (getTemplate) {
      const transformedNotifications = getTemplate?.data?.data?.map(
        (template) => ({
          _id: template._id,
          title: template.title,
          message: template.message,
        })
      );
      setNotificationsMessageList(transformedNotifications);
    }
  }, [getTemplate]);

  const handleNotificationChange = (event) => {
    setSendNotification(event.target.checked);
    if (!event.target.checked) {
      setNotificationMessage("");
    } else {
      setIsNotificationModalOpen(true);
    }
  };

  const updateTicketMutation = useMutation((ticketData) => {
    const { update: updateUrl } =
      roleBasedUrls[userRole] || roleBasedUrls.agent;
    return instance.patch(updateUrl, ticketData);
  });

  const handleSave = () => {
    const payload = {
      sendNotification: sendNotification,
      notificationMessage: notificationMesage,
      remarks: remarks,
      status: status,
      ticketId: selectedIds,
    };

    updateTicketMutation.mutate(payload, {
      onSuccess: () => {
        handleClose();
        setRemarks("");
        setSendNotification(false);
        setNotificationMessage("");
        refetch();
      },
      onError: (error) => {
        console.error("Error updating tickets:", error);
      },
    });
  };

  return (
    <>
      {isNotificationModalOpen && (
        <NotificationModal
          open={isNotificationModalOpen}
          handleClose={() => setIsNotificationModalOpen(false)}
          notifications={notificationsMessageList}
          onSelectMessage={handleSelectMessage}
        />
      )}

      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: style }}>
        <DialogTitle>Bulk Update</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            size="normal"
            margin="normal"
            required
            label="Remarks"
            value={remarks}
            multiline
            rows={2}
            onChange={(e) => setRemarks(e.target.value)}
          />
          <Box display="flex" alignItems="center" mt="1rem" gap="1rem">
            <Typography variant="span">Status Update:</Typography>
            <RadioGroup
              row
              sx={{ mt: ".5rem" }}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <FormControlLabel
                value="pending"
                control={<Radio size="small" />}
                label="Pending"
              />
              <FormControlLabel
                value="closed"
                control={<Radio size="small" />}
                label="Solved"
              />
            </RadioGroup>
            <FormControlLabel
              sx={{ mt: ".5rem" }}
              control={
                <Checkbox
                  checked={sendNotification}
                  onChange={handleNotificationChange}
                />
              }
              label="Notify User"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              disabled={remarks === ""}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BulkEditModal;

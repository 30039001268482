import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CallMade,
  CallReceived,
  Pause,
  Phone,
  PhoneForwarded,
  PlayArrow,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import React, { useState } from "react";
import dayjs from "dayjs";

const QueueCallLogsTable = ({
  tableData,
  tableDataLoading,
  currentPage,
  handleLeftClick,
  handleRightClick,
  handleFirstPageClick,
  handleLastPageClick,
  handlePageClick,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState("");

  const columns = [
    {
      field: "source",
      headerName: "Source",
      width: 200,
      renderCell: (cell) => {
        const phoneNumber = cell?.row?.source;
        return (
          <Box display="flex" alignItems="center" gap="10px">
            <Tooltip placement="top" title="Click to call or copy">
              <Typography
                fontSize="14px"
                component="span"
                onClick={(event) => handleOpenMenu(event, phoneNumber)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {phoneNumber}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },

    {
      field: "queue",
      headerName: "Queue",
      width: 200,
    },

    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
      renderCell: (cell) => {
        const date = cell?.row?.createdAt;
        const newDate = dayjs(date).format("MMMM DD YYYY");
        return (
          <Typography fontSize="14px" variant="span">
            {newDate}
          </Typography>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      width: 150,
      renderCell: (cell) => {
        const time = cell?.row?.createdAt;
        const dateObject = dayjs(time);
        const formattedTime = dateObject.format("HH:mm:ss");

        return (
          <Typography fontSize="14px" variant="span">
            {formattedTime}
          </Typography>
        );
      },
    },
  ];

  // Opens a menu when a phone number is clicked
  const handleOpenMenu = (event, phoneNumber) => {
    setAnchorEl(event.currentTarget);
    setSelectedNumber(phoneNumber);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleCopyNumber = () => {
    navigator.clipboard.writeText(selectedNumber);
    handleCloseMenu();
  };

  const handleCallNumber = () => {
    handleCallClick(selectedNumber);
    handleCloseMenu();
  };

  const handleCallClick = (phoneNumber) => {
    if (typeof window.makeCall === "function") {
      window.makeCall(phoneNumber);
    } else {
      console.error("makeCall function not found");
    }

    eventEmitter.emit("callClicked");
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleCopyNumber}>Copy</MenuItem>
        <MenuItem onClick={handleCallNumber}>Call</MenuItem>
      </Menu>
      <Box width="100%" height="67vh">
        {!tableDataLoading && tableData && (
          <>
            <DataGrid
              columns={columns}
              rows={tableData?.data}
              loading={tableDataLoading}
              hideFooter="true"
              getRowId={(row) => row._id}
            />
            <CustomPagination
              currentPage={currentPage}
              totalPage={tableData?.totalPages}
              handleLeftClick={handleLeftClick}
              handleRightClick={handleRightClick}
              handlePageClick={handlePageClick}
              handleFirstPageClick={handleFirstPageClick}
              handleLastPageClick={handleLastPageClick}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default QueueCallLogsTable;

import { Avatar, Box, Card, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import SendMessage from "./SendMessage";
import { chatinstance } from "app/utils/axiosRequest";

const MainChatBox = ({
  selectedConversation,
  handleFileChange,
  handleSendMessage,
  handleMessageChange,
  message,
  selectedRecipent,
}) => {
  const formatDate = (timeStamp) => {
    return dayjs(timeStamp).format("YYYY/MM/DD");
  };

  const formatTime = (timeStamp) => {
    return dayjs(timeStamp).format("HH:mm:ss");
  };

  const [previewImage, setPreviewImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setPreviewImage(imageUrl);
  };

  const closePreview = () => {
    setPreviewImage(null);
  };
  const chatBoxRef = useRef(null);

  useEffect(() => {
    // Scroll to the last message
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [selectedConversation]);

  return (
    <Box
      sx={{
        flex: 3,
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FAFAFA",
      }}
    >
      {selectedConversation ? (
        <Card
          ref={chatBoxRef}
          sx={{
            marginBottom: "20px",
            padding: "10px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            maxHeight: "70vh",
            overflowY: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "#888 #f1f1f1",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "8px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {selectedConversation.length > 0 ? (
            selectedConversation.map((message, index) => {
              const isDifferentDate =
                index === 0 ||
                formatDate(message.timeStamp) !==
                  formatDate(selectedConversation[index - 1].timeStamp);

              return (
                <React.Fragment key={index}>
                  {isDifferentDate && (
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        color: "#888",
                        marginBottom: "5px",
                      }}
                    >
                      {formatDate(message.timeStamp)}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent:
                        message.senderId === "viberBot" ||
                        message.senderId === "telegramBot" ||
                        message.senderId === "messengerBot" ||
                        message.senderId === "whatsappBot"
                          ? "flex-end"
                          : "flex-start",
                      marginBottom: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          message.senderId === "viberBot" ||
                          message.senderId === "telegramBot" ||
                          message.senderId === "messengerBot" ||
                          message.senderId === "whatsappBot"
                            ? "#DCF8C6"
                            : "#E8E8E8",
                        padding: "10px",
                        borderRadius: "8px",
                        maxWidth: "75%",
                      }}
                    >
                      <Box display="flex" flexDirection="column" gap="0.5rem">
                        {message?.messageType === "image" && message?.imageUrl && (
                          <img
                            src={`https://test-ciomni.waterflowtechnology.net/users/${message.imageUrl}`}
                            alt="image"
                            style={{
                              height: "200px", // Set your desired height
                              width: "300px", // Set your desired width
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleImageClick(
                                `https://test-ciomni.waterflowtechnology.net/users/${message.imageUrl}`
                              )
                            }
                          />
                        )}
                        {message?.messageType === "file" && message?.fileUrl && (
                          <a
                            href={`https://test-ciomni.waterflowtechnology.net/users/${message.fileUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {message.fileUrl}
                          </a>
                        )}
                        {message?.messageText}
                      </Box>

                      <Typography
                        variant="caption"
                        sx={{
                          color: "#888",
                          textAlign: "right",
                        }}
                      >
                        <Box display="flex" flexDirection="row" gap="10px">
                          {formatTime(message.timeStamp)}
                          {message?.type === "viber" && (
                            <Avatar
                              alt="customer image"
                              src="/viber_icon.svg"
                              sx={{
                                marginRight: "6px",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          )}
                          {message?.type === "telegram" && (
                            <Avatar
                              alt="customer image"
                              src="/telegram.svg"
                              sx={{
                                marginRight: "6px",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          )}
                          {message?.type === "messenger" && (
                            <Avatar
                              alt="customer image"
                              src="/messenger.svg"
                              sx={{
                                marginRight: "6px",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          )}
                          {message?.type === "whatsapp" && (
                            <Avatar
                              alt="customer image"
                              src="/whatsap.svg"
                              sx={{
                                marginRight: "6px",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          )}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })
          ) : (
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                fontSize: "25px",
                color: "#232C93",
                padding: "10px",
              }}
            >
              Select a chat to start messaging
            </Typography>
          )}
        </Card>
      ) : (
        <Typography variant="body2" sx={{ textAlign: "center", color: "#888" }}>
          No conversation selected
        </Typography>
      )}

      {/* send message component */}
      {selectedRecipent && (
        <SendMessage
          handleFileChange={handleFileChange}
          handleSendMessage={handleSendMessage}
          handleMessageChange={handleMessageChange}
          message={message}
        />
      )}

      {/* Image Preview */}
      {previewImage && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={closePreview}
        >
          <img
            src={previewImage}
            alt="preview"
            style={{
              maxHeight: "80%",
              maxWidth: "80%",
              objectFit: "contain",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default MainChatBox;

import React from "react";

import { Box, Button, Card, CardContent } from "@mui/material";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import TextfieldComponent from "app/components/Shared/Textfield/Textfield.component";

const FilterComponent = ({
  filterData,
  handleChange,
  handleCreatedFrom,
  handleCreatedTo,
  handleFilter,
}) => {
  return (
    <>
      {/* Filter component */}
      <Box display="flex" margin="20px 0" justifyContent="space-between">
        <Box width="10em">
          <TextfieldComponent
            label={"Extension"}
            handlechange={handleChange}
            value={filterData.extension}
            name={"extension"}
            size="small"
            color="primary"
            fullWidth
          />
        </Box>
        <Box display="flex" gap="10px">
          <DatePicker
            label={"Created From"}
            value={filterData?.from}
            handleChange={handleCreatedFrom}
            name="from"
          />
          <DatePicker
            label={"Created To"}
            value={filterData?.to}
            handleChange={handleCreatedTo}
            name="to"
          />
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleFilter}
          >
            Filter
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default FilterComponent;

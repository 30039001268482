import {
  AddCircle,
  Delete,
  DeleteForeverOutlined,
  TableRows,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";

import React, { useState } from "react";

const TableFieldComponent = ({
  FieldTypeOptions,
  tableField,
  handleAddTableField,
  handleDeleteTableField,
  handleTableFieldChange,
}) => {
  return (
    <Box
      sx={{
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "15px",
      }}
    >
      <Typography color="#232C93" fontSize="20px" variant="span">
        <TableRows sx={{ marginRight: "8px" }} />
        Table Field
        <IconButton onClick={handleAddTableField}>
          <AddCircle />
        </IconButton>
      </Typography>
      {tableField?.map((input, index) => (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              overflow: "auto",
            }}
          >
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                overflow: "auto",
              }}
            >
              {/* name of field */}
              <Box display="flex" flexDirection="column" gap="10px">
                <Typography color="#232C93" fontSize="20px" variant="span">
                  {" "}
                  Field Name{" "}
                </Typography>
                <TextField
                  key={index}
                  name="field_name"
                  value={input.field_name}
                  onChange={(event) => handleTableFieldChange(index, event)}
                  size="small"
                  fullWidth
                  sx={{ width: "200px" }}
                />
              </Box>
              {/* type of field */}
              <Box display="flex" flexDirection="column" gap="10px">
                <Typography color="#232C93" fontSize="20px" variant="span">
                  {" "}
                  Field Type{" "}
                </Typography>

                <Box display="flex" flexDirection="row" gap="10px">
                  <Select
                    key={index}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={input.field_type}
                    name="field_type"
                    onChange={(event) => handleTableFieldChange(index, event)}
                    fullWidth
                    required
                    sx={{
                      width: "150px",
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {FieldTypeOptions?.map((item) => (
                      <MenuItem value={item?.label} key={item?.item}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {index > 0 && (
                    <IconButton onClick={() => handleDeleteTableField(index)}>
                      <Delete />
                    </IconButton>
                  )}

                  {input.field_type === "Select" && (
                    <>
                      <Box>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={[]}
                          freeSolo
                          value={input.field_options}
                          onChange={(event, value) =>
                            handleTableFieldChange(index, {
                              target: { name: "field_options", value },
                            })
                          }
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                width: "40rem",
                              }}
                              {...params}
                              label="Add options"
                              size="small"
                              placeholder="Yes,No"
                            />
                          )}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ))}
    </Box>
  );
};

export default TableFieldComponent;

import { Alert, Button, CircularProgress, Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@tanstack/react-query";
import {
  addIssueCategory,
  allcategoryList,
} from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import { useState } from "react";

const DeleteIssueCategoryDialog = ({ open, onClose, issueId, refetch }) => {
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  const { mutate: deleteIssueCategory, isLoading } = useMutation(
    ["delete-issue-category"],
    () => instance.delete(`${allcategoryList.category_list}/${issueId}`),
    {
      onSuccess: () => {
        refetch();
        onClose();
        setSnackBar({
          open: true,
          severity: "success",
          message: "Issue-Category Deleted successfully!",
        });
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error! Please Try Again Later",
        });
      },
    }
  );

  const handleSubmit = () => {
    deleteIssueCategory();
  };
  return (
    <>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this sub-issue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteIssueCategoryDialog;

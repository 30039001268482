export function addMessage(message) {
  return (dispatch) => {
    dispatch({
      type: "ADD_MESSAGE",
      message,
    });
  };
}

export function deleteMessage() {
  return (dispatch) => {
    dispatch({
      type: "DELETE_MESSAGE",
    });
  };
}

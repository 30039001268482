import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Button,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import { ContactPhone, Download, Preview } from "@mui/icons-material";

const Tableassigned = ({
  isLoading,
  tableData,
  handleLeftClick,
  handleRightClick,
}) => {

  const columns = [
    {
      field: "ticketId",
      headerName: "Ticket Id",
      width: 120,
    },
    {
      field: "clientId",
      headerName: "Client Id",
      width: 130,
    },
    {
      field: "clientName",
      headerName: "Client Name",
      width: 150,
    },
    {
      field: "clientPhoneNumber",
      headerName: "Phone Number",
      width: 140,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
    },

    {
      field: "openedBy",
      headerName: "Assigned By",
      width: 110,
    },
    {
      field: "ticketStatus",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        const status = params?.row?.ticketStatus;
        return (
          <Typography
            variant="span"
            color={status === "pending" ? "#E7625F" : "#81B622"}
          >
            {status.toUpperCase()}
          </Typography>
        );
      },
    },
    {
      field: "issueCategory",
      headerName: "Issue Category",
      width: 150,
    },
    {
      field: "issueSubCategory",
      headerName: "Issue Sub Category",
      width: 150,
    },

    {
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        const status = params?.row?.ticketStatus;
        const data = params?.row;

        return (
          <Box display="flex" alignItems="center" gap="15px">
            <Tooltip title="View Ticket">
              <Preview
                sx={{ color: "#C26DBC", cursor: "pointer" }}
                // onClick={() => handleViewTicket(data)}
              />
            </Tooltip>
            {status === "pending" && (
              <Tooltip title="Follow Up">
                <ContactPhone sx={{ color: "#A06204", cursor: "pointer" }} />
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box width="100%" height="70vh">
        {!isLoading && tableData && (
          <>
            <DataGrid
              columns={columns}
              rows={tableData?.data}
              getRowId={(rows) => rows["ticketId"]}
              hideFooter="true"
            />
            <CustomPagination
              currentPage={tableData?.currentPage}
              totalPage={tableData?.totalPages}
              handleLeftClick={handleLeftClick}
              handleRightClick={handleRightClick}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default Tableassigned;

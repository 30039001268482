import React from "react";
import DialpadComponent from "./Dialpadtocall";
import IncomimgDialpad from "./IncomingCallDialpad";

const Dialpad = () => {
  return (
    <>
      {/* <DialpadComponent /> */}
      <IncomimgDialpad />
    </>
  );
};

export default Dialpad;

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const SelectComponent = ({
  options,
  label,
  value,
  handleChange,
  name,
  required,
}) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          required={required}
          value={value}
          label={label}
          onChange={handleChange}
          size="small"
          name={name}
        >
          {options?.map((option) => {
            return (
              <MenuItem key={option?.id} value={option?.value}>
                {option?.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectComponent;

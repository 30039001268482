export function activateLoading() {
  return (dispatch) => {
    dispatch({
      type: "ACTIVATE_LOADING",
    });
  };
}

export function deactivateLoading() {
  return (dispatch) => {
    dispatch({
      type: "DEACTIVATE_LOADING",
    });
  };
}

export function increaseLoading() {
  return (dispatch) => {
    dispatch({
      type: "INCREASE_LOADING_COUNT",
    });
  };
}

export function resetLoading() {
  return (dispatch) => {
    dispatch({
      type: "RESET_LOADING",
    });
  };
}

export function addMessage(message) {
  return (dispatch) => {
    dispatch({
      type: "ADD_MESSAGE",
      message,
    });
  };
}

export function deleteMessage() {
  return (dispatch) => {
    dispatch({
      type: "DELETE_MESSAGE",
    });
  };
}

const init = { flag: false, loadingCount: 0, top: 72, fullPageLoader: false };

export function loading(state = init, action) {
  switch (action.type) {
    case "ACTIVATE_LOADING":
      return {
        flag: true,
        loadingCount: state.loadingCount + 1,
      };
    case "INCREASE_LOADING_COUNT":
      return {
        ...state,
        loadingCount: state.loadingCount + 1,
      };

    case "DEACTIVATE_LOADING":
      if (state.loadingCount > 1) {
        return {
          ...state,
          loadingCount: state.loadingCount - 1,
        };
      } else {
        return { ...init };
      }
    case "RESET_LOADING":
      return { ...init };
    default:
      return state;
  }
}

export function message(state = { message: null }, action) {
  switch (action.type) {
    case "ADD_MESSAGE":
      return { ...state, ...{ message: action.message } };

    case "DELETE_MESSAGE":
      return { ...state, ...{ message: null } };

    default:
      return state;
  }
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Modal,
} from "@mui/material";
import dayjs from "dayjs";

import { Add, Download } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import UserSettingTable from "./UserSettingTable";
import CommonReportFilter from "app/pages/AdminDashboard/CommonReportFilter";
import AddUserSetting from "./AddUserSetting";
import EditUserModal from "./EditUserModal";

const UserSetting = () => {
  const [filterData, setFilterData] = useState({
    role: "",
    name: "",
    email: "",
    mobileNumber: "",
  });

  const [addUser, setaddUser] = useState({
    open: false,
  });

  const [editUser, setEditUser] = useState({
    open: false,
    data: "",
  });

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    });
  };

  const Role = [
    {
      id: 1,
      value: "agent",
      label: "Agent",
    },
    {
      id: 2,
      value: "admin",
      label: "Admin",
    },

    {
      id: 3,
      value: "supervisor",
      label: "Supervisor",
    },
  ];

  const handleCloseAddModal = () => {
    setaddUser({
      open: false,
    });
  };

  const handleopenAddModal = () => {
    setaddUser({
      open: true,
    });
  };

  const handleEditUserModal = (data) => {
    setEditUser({
      open: true,
      data: "",
    });
  };

  const handleeditClose = () => {
    setEditUser({
      open: false,
    });
  };

  return (
    <>
      <AddUserSetting open={addUser.open} handleClose={handleCloseAddModal} />
      <EditUserModal open={editUser.open} handleeditClose={handleeditClose} />
      <FullpageLoader
      //   open={isLoading}
      />
      <Box display="flex" gap="10px" width="100%" marginTop="1em">
        <Card sx={{ width: "25%" }}>
          <CardContent>
            <CommonReportFilter
              filterData={filterData}
              handleChange={handleChange}
              //   handleClear={handleClear}
              //   handleFilter={handleFilter}
              //   isLoading={isRefetching}
              rolecategories={Role}
              reportType="usersetting"
            />
          </CardContent>
        </Card>
        <Box width="75%">
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="flex-end" marginBottom="10px">
                <Button
                  variant="contained"
                  sx={{ bgcolor: "green" }}
                  startIcon={<Add />}
                  onClick={handleopenAddModal}
                  //   isLoading={loadingfetching}
                >
                  Add New User
                  {/* {isLoading ? <CircularProgress /> : "DOWNLOAD"} */}
                </Button>
              </Box>
              <UserSettingTable handleEditUserModal={handleEditUserModal} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default UserSetting;

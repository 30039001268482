import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Button } from "@mui/material";

import dayjs from "dayjs";
import { Download } from "@mui/icons-material";

import { instance } from "app/utils/axiosRequest";
import { useQuery } from "@tanstack/react-query";
import HourlyReportTable from "./HourlyReportTable";
import CommonReportFilter from "app/pages/AdminDashboard/CommonReportFilter";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import {
  adminqueueReport,
  allQueueList,
} from "app/utils/api-urls/AdminAPiUrls";

const HourlyReport = () => {
  const [queue, setqueueData] = useState([]);

  //Hourly Queue Report
  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    ["admin-Hourly-Report"],
    () => instance.post(adminqueueReport.queue_report, filterData)
  );

  const {
    data: data1,
    isLoading: isLoading1,
    error: error1,
    refetch: reftech1,
    isRefetching: isRefetching1,
  } = useQuery(["admin-all-queue-list"], () =>
    instance.get(allQueueList.allqueue_list)
  );

  useEffect(() => {
    if (data1?.data) {
      setqueueData(
        data1?.data?.map((item) => {
          return {
            id: item?._id,
            value: item?.queueNumber,
            label: item?.queueName,
          };
        })
      );
    }
  }, [data1?.data]);

  const [filterData, setFilterData] = useState({
    queue: "all",
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),

    // page: 1,
  });

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleClear = () => {
    setFilterData({
      ...filterData,
      queue: "",
      from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
    });
  };

  const handleFilter = () => {
    refetch();
  };

  // const queue = [
  //   {
  //     id: 1,
  //     value: "all",
  //     label: "All",
  //   },
  //   {
  //     id: 2,
  //     value: "5017",
  //     label: "off-office-callcenter",
  //   },
  //   {
  //     id: 3,
  //     value: "5111",
  //     label: "Reception",
  //   },
  //   {
  //     id: 4,
  //     value: "5112",
  //     label: "DP Queue",
  //   },
  //   {
  //     id: 5,
  //     value: "5113",
  //     label: "Trading Queue",
  //   },
  //   {
  //     id: 6,
  //     value: "5114",
  //     label: "Account Queue",
  //   },
  //   {
  //     id: 7,
  //     value: "5115",
  //     label: "KYC Queue",
  //   },
  //   {
  //     id: 8,
  //     value: "601",
  //     label: "Test Queue",
  //   },
  // ];

  return (
    <>
      <FullpageLoader open={isLoading} />
      <Box display="flex" gap="10px" width="100%" marginTop="1em">
        <Card sx={{ width: "25%" }}>
          <CardContent>
            <CommonReportFilter
              reportType="HourlyReport"
              handleClear={handleClear}
              handleFilter={handleFilter}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              QueueCategoryOptions={queue}
              filterData={filterData}
              handleChange={handleChange}
              isLoading={isRefetching}
            />
          </CardContent>
        </Card>

        <Box width="75%">
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="flex-end" marginBottom="10px">
                <Button variant="contained" startIcon={<Download />}>
                  Download
                </Button>
              </Box>
              <HourlyReportTable
                isLoading={isLoading}
                tableData={data?.data?.hourlyReport}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default HourlyReport;

import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { routes } from "app/routes";

const CollapsedMenu = ({ subRoutes, open, isPathActive }) => {
  return (
    <Collapse in={open}>
      <ul className="nav flex-column sub-menu">
        {subRoutes.map((e) => {
          if (e.hideInSidebar) return null;
          return (
            <li className="nav-item" key={e.path}>
              <Link
                className={
                  isPathActive(e.path) ? "nav-link active" : "nav-link"
                }
                to={e.path}
              >
                <i className={`mdi mdi-${e.icon} menu-icon`}></i>
                {e.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </Collapse>
  );
};

const Sidebar = ({ drawerOpen }) => {
  const location = useLocation();
  const [state, setState] = useState({});

  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  // const className = drawerOpen ? "" : "active";
  return (
    <nav className={`sidebar sidebar-offcanvas `} id="sidebar">
      <ul className="nav">
        {routes.map((e) => {
          if (e.hideInSidebar) return null;
          if (e.dropDownState) {
            return (
              <li
                className={
                  isPathActive(e.shortPath) ? "nav-item active" : "nav-item"
                }
                key={e.path}
              >
                <div
                  className={
                    state[e.dropDownState]
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => toggleMenuState(e.dropDownState)}
                  data-toggle="collapse"
                >
                  <span className="menu-title">{e.name}</span>
                  <i className="menu-arrow"></i>
                  <i className={`mdi mdi-${e.icon} menu-icon`}></i>
                </div>
                <CollapsedMenu
                  subRoutes={e.dropDownMenu}
                  open={state[e.dropDownState]}
                  isPathActive={isPathActive}
                />
              </li>
            );
          }
          return (
            <li
              key={e.path}
              className={
                isPathActive(e.shortPath) ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to={e.path}>
                <span className="menu-title">
                  <Trans>{e.name}</Trans>
                </span>
                <i className="menu-arrow"></i>
                <i className={`mdi mdi-${e.icon} menu-icon`}></i>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../../css/editor.css";
import { stateToHTML } from "draft-js-export-html";
import React, { useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

import {
  Box,
  Typography,
  Button,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import { height } from "@mui/system";

const Knowledgebase = ({ placeholder }) => {
  const [body, setBody] = useState();
  const [editorContentHtml, setEditorContentHtml] = useState("");

  // useEffect(() => {
  //   setEditorContentHtml(stateToHTML(body.getCurrentContent()));
  // }, [body]);

  console.log(body, "html");
  const editor = useRef(null);
  const [content, setContent] = useState("");

  // const config = useMemo(
  //   {
  //     readonly: false, // all options from https://xdsoft.net/jodit/doc/,
  //     placeholder: placeholder || "Start typings...",
  //   },
  //   [placeholder]
  // );

  console.log(content, "content");

  const config = {
    zIndex: 0,
    readonly: false,
    activeButtonsInReadOnly: ["source", "fullsize", "print", "about"],
    toolbarButtonSize: "middle",
    theme: "default",
    enableDragAndDropFileToEditor: true,
    saveModeInCookie: false,
    spellcheck: true,
    editorCssClass: false,
    triggerChangeEvent: true,
    height: 220,
    direction: "ltr",
    language: "en",
    debugLanguage: false,
    i18n: "en",
    tabIndex: -1,
    toolbar: true,
    enter: "P",
    useSplitMode: false,
    colorPickerDefaultTab: "background",
    imageDefaultWidth: 100,
    removeButtons: [
      "source",
      "fullsize",
      "about",
      "outdent",
      "indent",
      "video",
      "print",
      "table",
      "fontsize",
      "superscript",
      "subscript",
      "file",
      "cut",
      "selectall",
    ],
    disablePlugins: ["paste", "stat"],
    events: {},
    textIcons: false,
    uploader: {
      insertImageAsBase64URI: true,
    },
    placeholder: "",
    showXPathInStatusbar: false,
  };

  return (
    <>
      <div className="App">
        <Box
          sx={{
            backgroundColor: "#282c34",
            height: "5vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            padding: "12px",
            marginTop: "1rem",
          }}
        >
          Knowledge Base Text Editor
        </Box>
        <Box
          sx={{
            marginTop: "1rem",
            borderRadius: "2px",
            border: 2,
            borderColor: "error.main",
          }}
        >
          <JoditEditor
            ref={editor}
            config={config}
            value={content}
            tabIndex={4} // tabIndex of textarea
            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(newContent) => {}}
          />
        </Box>

        {/* <Editor
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          editorState={body}
          onEditorStateChange={setBody}
        /> */}
      </div>
    </>
  );
};

export default Knowledgebase;

import{
    VIPACCESS_FETCHED
} from './vipAccessActionTypes';

const init = {
    allVip :[],
    totalPages:null
}

const vipAccessReducer = (state = init, action) =>{
    const {type, payload} = action;

    switch (type) {
        case VIPACCESS_FETCHED:
            return{
                ...state,
                allVip: payload.vipAccesses,
                totalPages: payload.pagination.pages
            }
        default:
            return state;
    }

} 

export default vipAccessReducer;
import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
  CircularProgress,
  LinearProgress,
  Tabs,
  Tab,
} from "@mui/material";
import { CallEnd } from "@mui/icons-material";

const InboundCall = () => {
  return (
    <>
      <Box display="flex" marginBottom="-30px" flexDirection="row" gap="30px">
        <Box
          display="flex"
          marginTop="12px"
          flexDirection="column"
          gap="20px"
          width="20%"
        >
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box display="flex" flexDirection="column" gap="2px">
                <Typography
                  display="flex"
                  justifyContent="center"
                  variant="span"
                  fontSize="20px"
                  color="#4E9F3D"
                >
                  567
                </Typography>
                <Typography variant="span" fontSize="13px">
                  Total Calls
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box display="flex" flexDirection="column" gap="2px">
                <Typography
                  display="flex"
                  justifyContent="center"
                  variant="span"
                  fontSize="20px"
                  color="#4E9F3D"
                >
                  54
                </Typography>
                <Typography variant="span" fontSize="13px">
                  Missed Calls
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box display="flex" flexDirection="column" gap="2px">
                <Typography
                  display="flex"
                  justifyContent="center"
                  variant="span"
                  fontSize="20px"
                  color="#4E9F3D"
                >
                  56
                </Typography>
                <Typography variant="span" fontSize="13px">
                  Received Calls
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
        {/* Call Report Inside box */}
        <Card
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "40px",
                }}
              >
                <Card>
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box display="flex" flexDirection="column" gap="2px">
                      <Typography
                        display="flex"
                        justifyContent="center"
                        variant="span"
                        fontSize="20px"
                        color="#4E9F3D"
                      >
                        5:20:12
                      </Typography>
                      <Typography variant="span" fontSize="13px">
                        Total Talk Time
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box display="flex" flexDirection="column" gap="2px">
                      <Typography
                        display="flex"
                        justifyContent="center"
                        variant="span"
                        fontSize="20px"
                        color="#4E9F3D"
                      >
                        5:20:12
                      </Typography>
                      <Typography variant="span" fontSize="13px">
                        Average Talk Time
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  flexDirection: "row",
                }}
              >
                <Card>
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box display="flex" flexDirection="column" gap="2px">
                      <Typography
                        display="flex"
                        justifyContent="center"
                        variant="span"
                        fontSize="20px"
                        color="#4E9F3D"
                      >
                        5:20:12
                      </Typography>
                      <Typography variant="span" fontSize="13px">
                        Highest Talk Time
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box display="flex" flexDirection="column" gap="2px">
                      <Typography
                        display="flex"
                        justifyContent="center"
                        variant="span"
                        fontSize="20px"
                        color="#4E9F3D"
                      >
                        5:20:12
                      </Typography>
                      <Typography variant="span" fontSize="13px">
                        Lowest Talk Time
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default InboundCall;

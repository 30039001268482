import { USERS_FETCHED, USER_ADDED } from "./UsersActionTypes";

const init = {
  users: [],
  totalPages:null
};
const UsersReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case USERS_FETCHED:
      return { ...state, users: payload?.data?.users, totalPages:payload?.data?.pagination?.pages };
    case USER_ADDED:
      return { ...state, users: [...state.users, payload?.data?.user] };
    default:
      return state;
  }
};

export default UsersReducer;

import React from "react";

import { CallMade, CallReceived, PhoneForwarded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ReactAudioPlayer from "react-audio-player";

const IssueReportTable = () => {
  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 80,
    },
    {
      field: "issueCategory",
      headerName: "Issue Category",
      width: 250,
    },
    {
      field: "count",
      headerName: "Count",
      width: 110,
    },
    {
      field: "fromDate",
      headerName: "From Date",
      width: 180,
    },
    {
      field: "toDate",
      headerName: "To Date",
      width: 180,
    },
  ];

  const rows = [
    {
      id: 1,
      issueCategory: "Issue 1",
      count: "10",
      fromDate: "08/02/2022",
      toDate: "15/02/2022",
    },
    {
      id: 2,
      issueCategory: "Issue 1",
      count: "10",
      fromDate: "08/02/2022",
      toDate: "15/02/2022",
    },
    {
      id: 3,
      issueCategory: "Issue 1",
      count: "10",
      fromDate: "08/02/2022",
      toDate: "15/02/2022",
    },
    {
      id: 4,
      issueCategory: "Issue 1",
      count: "10",
      fromDate: "08/02/2022",
      toDate: "15/02/2022",
    },
    {
      id: 5,
      issueCategory: "Issue 1",
      count: "10",
      fromDate: "08/02/2022",
      toDate: "15/02/2022",
    },
  ];

  return (
    <Box width="100%" height="62vh">
      <DataGrid columns={columns} rows={rows} pageSize={5} />
    </Box>
  );
};

export default IssueReportTable;

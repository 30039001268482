import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  addIssueCategory,
  allQueueList,
} from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import { useMutation, useQuery } from "@tanstack/react-query";

const AddIssueCategory = ({ open, handleClose, refetch }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [issueName, setIssueName] = useState("");
  const [queue, setQueueData] = useState([]);

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 6,
    borderRadius: 5,
  };

  const { data: data1 } = useQuery(["admin-all-queue-list"], () =>
    instance.get(allQueueList.allqueue_list)
  );

  useEffect(() => {
    if (data1?.data) {
      setQueueData(
        data1?.data?.map((item) => {
          return {
            id: item?._id,
            label: item?.name,
          };
        })
      );
    }
  }, [data1?.data]);

  const mutation = useMutation(
    ["post-issue-category"],
    (newIssueCategory) =>
      instance.post(addIssueCategory.issue_category, newIssueCategory),
    {
      onSuccess: () => {
        setIssueName("");
        setSelectedOptions([]);
        handleClose();
        refetch();
        setSnackBar({
          open: true,
          severity: "success",
          message: "Issue category created successfully!",
        });
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error adding template!",
        });
      },
    }
  );

  const handleSelectChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  const handleIssueChange = (event) => {
    setIssueName(event.target.value);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  const handleSubmit = () => {
    const payload = {
      name: issueName,
      queues: selectedOptions,
    };
    mutation.mutate(payload);
  };

  const renderQueueLabels = (selectedQueueIds) => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {selectedQueueIds.map((queueId) => {
          const queueItem = queue.find((q) => q.id === queueId);
          return <Chip key={queueId} label={queueItem?.label || queueId} />;
        })}
      </Box>
    );
  };

  return (
    <>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl fullWidth>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New Issue
            </Typography>
            <Box marginTop="12px" display="flex" flexDirection="row" gap="1rem">
              <TextField
                sx={{ width: "25ch" }}
                label="Issue Category Name"
                size="normal"
                value={issueName}
                onChange={handleIssueChange}
                required
                autoComplete="off"
              />
              <FormControl sx={{ width: "45ch" }}>
                <InputLabel id="mutiple-select-label">Queue List</InputLabel>
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  input={<OutlinedInput label="Queue List" />}
                  renderValue={renderQueueLabels}
                >
                  {queue?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="flex-end" marginTop="20px">
              <Button
                onClick={handleSubmit}
                size="medium"
                variant="contained"
                disabled={!issueName.trim()}
              >
                Submit
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

export default AddIssueCategory;

import React, { useState } from "react";
import Box from "@mui/material/Box";

import { Card } from "@mui/material";
import CreateCampaignComponent from "app/components/CreateCampaign/RegisterCampaign";
import StepperComponent from "app/components/CreateCampaign/RegisterCampaign/stepperComponent";
import UploadLead from "app/components/CreateCampaign/UploadLead";
import ViewCampaign from "app/components/CreateCampaign/ViewCampaign";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addregistercampaign,
  getregisterCampaignByIdForTable,
} from "app/utils/api-urls/AdminAPiUrls";
import dayjs from "dayjs";
import { outboundCampaign } from "app/utils/axiosRequest";

const createCampaign = () => {
  // For Stepper Component
  const steps = ["Create Campaign", "Upload Leads", "View Campaign"];
  const [currentStep, setCurrentStep] = useState(0);

  const handleSaveAndContinue = (data) => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  // forCreateCampaignComponent

  const [registerCampaignData, setRegisterCampaignData] = useState({
    campaignName: "",
    description: "",
    startDate: "",
    endDate: "",
    fields: [],
  });

  const handleToDate = (value) => {
    setRegisterCampaignData({
      ...registerCampaignData,
      endDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFromDate = (value) => {
    setRegisterCampaignData({
      ...registerCampaignData,
      startDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleTextChange = (e) => {
    setRegisterCampaignData({
      ...registerCampaignData,
      campaignName: e.target.value,
    });
  };

  const handleDescriptionChange = (e) => {
    setRegisterCampaignData({
      ...registerCampaignData,
      description: e.target.value,
    });
  };

  // for table field
  const FieldTypeOptions = [
    {
      id: 1,
      value: "Textfield",
      label: "Text Field",
    },
    {
      id: 2,
      value: "Select",
      label: "Select",
    },
  ];

  const [tableField, setTableField] = useState([
    {
      field_name: "",
      field_type: "",
      field_options: "",
    },
  ]);

  const handleAddTableField = () => {
    let newTableField = {
      field_name: "",
      field_type: "",
      field_options: "",
    };
    setTableField([...tableField, newTableField]);
  };

  //Delete Table Field

  const handleDeleteTableField = (index) => {
    setTableField((prevTableField) =>
      prevTableField.filter((_, i) => i !== index)
    );
  };

  const handleTableFieldChange = (index, event) => {
    let data = [...tableField];
    data[index][event.target.name] = event.target.value;
    setTableField(data);
  };

  const [responseData, setResponseData] = useState("");

  const [uploadLeadsData, setuploadLeadsData] = useState("");

  const {
    isLoading: registerCapmiagnLoading,
    error: registerCampaignerror,
    mutate: registerCampaignmutate,
  } = useMutation(["register-campaign"], (payload) =>
    outboundCampaign.post(addregistercampaign.add_register_campaign, payload)
  );

  const handleSubmit = () => {
    const payload = {
      campaignName: registerCampaignData.campaignName,
      description: registerCampaignData.description,
      startDate: registerCampaignData.startDate,
      endDate: registerCampaignData.endDate,
      fields: tableField?.map((field) => ({
        key: field.field_name,
        type: field.field_type,
        options: field.field_options,
      })),
    };
    registerCampaignmutate(payload, {
      onSuccess: (data) => {
        setResponseData(data?.data?.data);

        handleSaveAndContinue();
      },
    });
  };

  const { isLoading, data, error, refetch } = useQuery(
    ["get-register-campaign-data", responseData],
    () =>
      outboundCampaign.get(
        `/outbound-campaign/registered-campaign/${responseData}`
      ),
    {
      onSuccess: (data) => {
        setuploadLeadsData(data?.data);
      },
    }
  );

  return (
    <>
      {/* Stepper */}
      <Card sx={{ marginTop: "20px" }}>
        <StepperComponent steps={steps} currentStep={currentStep} />
        {currentStep === 0 && (
          <CreateCampaignComponent
            registerCampaignData={registerCampaignData}
            handleToDate={handleToDate}
            handleFromDate={handleFromDate}
            handleTextChange={handleTextChange}
            handleDescriptionChange={handleDescriptionChange}
            tableField={tableField}
            FieldTypeOptions={FieldTypeOptions}
            handleAddTableField={handleAddTableField}
            handleDeleteTableField={handleDeleteTableField}
            handleTableFieldChange={handleTableFieldChange}
            handleSubmit={handleSubmit}
          />
        )}
        {currentStep === 1 && (
          <UploadLead
            handleSaveAndContinue={handleSaveAndContinue}
            handleBack={handleBack}
            uploadLeadsData={uploadLeadsData}
          />
        )}
        {currentStep === 2 && <ViewCampaign />}
      </Card>
    </>
  );
};

export default createCampaign;

import {
  Person,
  Badge,
  PendingActions,
  CheckCircle,
  Grid3x3,
} from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: 2,
};

const ViewSupervisorTicketModal = ({ open, handleClose, data }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end" gap="50px">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="5px"
            >
              <Box display="flex" alignItems="center" gap="5px">
                <Person />
                <Typography variant="span">{data?.clientName}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="5px">
                <Badge />
                <Typography variant="span">{data?.clientId}</Typography>
              </Box>
            </Box>
            <Box>
              {data?.ticketStatus === "pending" ? (
                <PendingActions sx={{ color: "#E7625F", fontSize: "30px" }} />
              ) : (
                <CheckCircle sx={{ color: "#81B622", fontSize: "30px" }} />
              )}
            </Box>
          </Box>
          <Card sx={{ marginTop: "1em" }}>
            <CardContent sx={{ marginTop: "1em" }}>
              <Box position="fixed" top="21px">
                <Avatar
                  alt={data?.clientName}
                  sx={{ width: 100, height: 100 }}
                />
              </Box>
              <Box>
                <Box
                  color={
                    data?.ticketStatus === "pending" ? "#E7625F" : "#81B622"
                  }
                  marginBottom="10px"
                >
                  <Grid3x3 />
                  <Typography variant="span">{data?.ticketId}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  fontSize="13px"
                  justifyContent="space-between"
                  gap="10px"
                >
                  <Box width="45%">
                    <Typography variant="span">Issue Category: </Typography>
                    <Typography variant="span">
                      {data?.issueCategory}
                    </Typography>
                  </Box>
                  <Box width="45%">
                    <Typography variant="span">Sub Issue Category: </Typography>
                    <Typography variant="span">
                      {data?.issueSubCategory}
                    </Typography>
                  </Box>
                  <Box width="45%">
                    <Typography variant="span">Remarks: </Typography>
                    <Typography variant="span">{data?.issueRemarks}</Typography>
                  </Box>
                  <Box width="45%">
                    <Typography variant="span">Opened By: </Typography>
                    <Typography variant="span">{data?.openedBy}</Typography>
                  </Box>
                  <Box width="45%">
                    <Typography variant="span">Assigned To: </Typography>
                    <Typography variant="span">{data?.assignedTo}</Typography>
                  </Box>
                  <Box width="45%">
                    <Typography variant="span">Phone Number: </Typography>
                    <Typography variant="span">
                      {data?.clientPhoneNumber}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default ViewSupervisorTicketModal;

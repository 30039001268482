import SupervisorTicketReport from "app/pages/SupervisorReport/TicketReport/TicketReport";
import AssignedToMe from "app/pages/assignedToMe";
const SupervisorTicketReportRoutes = [
  {
    path: "/app/ticket/supervisor-report",
    shortPath: "/ticket/ticket-list",
    name: "Ticket List",
    icon: "mdi mdi-clipboard-list-outline",
    component: SupervisorTicketReport,
  },
  {
    path: "/app/ticket/assigned-me",
    shortPath: "/ticket/assigned--me",
    name: "Assigned-to-me",
    icon: "mdi mdi-clipboard-list-outline",
    component: AssignedToMe,
  },
];

export default SupervisorTicketReportRoutes;

//Call Report
export const SupervisorCallReport = {
  Call_Report: "supervisor/report/call",
};

//TicketReport

export const SupervisorTicketReportApi = {
  Ticket_Report: "supervisor/report/tickets",
  assigned_to_me: "/supervisor/report/assigned-ticket",
};

export const SupervisorVoicemailTicketReportApi = {
  Voicemail_Ticket_Report: "supervisor/report/ticket/voicemail",
};

export const SupervisorChatTicketReportApi = {
  Chat_Ticket_Report: "supervisor/report/ticket/chat",
};
//Dashboard

export const SupervisorDashboardApi = {
  Dashboard: "supervisor/stats/dashboard",
};

//get all agent

export const SuperVisorAgentsApi = {
  SuperVisorAgents: "supervisor/agents",
};

export const supervisorlistenRecording = {
  supervisor_listen_recording: "supervisor/recording/ticket",
};

export const supervisorlistenVoicemail = {
  supervisor_voicemail_recording: "supervisor/recording/voicemail",
};

//to update ticet status,remarks
export const supervisorupdateTicketStatus = {
  supervisor_update_ticket_status: "supervisor/solve-ticket",
};

export const supervisorallcategoryList = {
  supervisor_category_list: "supervisor/issue",
};

export const supervisorallAgent = {
  supervisor_all_agent: "supervisor/agents",
};

export const supervisorpickTicketapi = {
  supervisor_pickedup_ticket_api: "supervisor/ticket/pick-ticket",
};

export const updateSupervisorTicket = {
  update_supervisor_ticket: "supervisor/ticket",
};

export const supervisorgetEmailTemplate = {
  supervisor_get_email_template: "supervisor/template",
};

export const supervisorGetallUser = {
  supervisor_get_all_user: "supervisor/users",
};

export const supervisorGetallQueue = {
  supervisor_get_all_user: "supervisor/queues",
};

export const supervisorAssignTicket = {
  supervisor_assigned_ticket: "supervisor/assign-ticket",
};

export const supervisorAssignTicketDepartment = {
  supervisor_assigned_ticket_department: "supervisor/assign-ticket-department",
};

export const supervisordownloadTickets = {
  supervisor_download_tickets: "supervisor/download/report/ticket",
};

export const supervisorbulkTickets = {
  supervisor_bulk_tickets: "supervisor/bulk-ticket",
};

export const SupervisorbreakButton = {
  extension_status: "supervisor/extension-status",
  extension_pause: "supervisor/break",
  break_options: "supervisor/breaks",
};

import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
  CircularProgress,
  LinearProgress,
} from "@mui/material";

import dayjs from "dayjs";


import FilterComponent from "./FilterComponent";
import { CircularProgressbar } from "react-circular-progressbar";

import { Doughnut, Pie, Bar } from "react-chartjs-2";
import FreeBreakfast from "@mui/icons-material/FreeBreakfast";
import Restaurant from "@mui/icons-material/Restaurant";
import CallReportsSection from "./CallReportsSection";
import DialpadComponent from "app/Dialpad/Dialpadtocall";

const AgentSummaryReport = () => {
  const [filterData, setFilterData] = useState({
    extension: "",
    fromDate: dayjs().subtract(7, "days").format("MM/DD/YYYY"),
    toDate: dayjs().format("MM/DD/YYYY"),
  });

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFilter = () => {};
  const props = 90;
  const percentage = 75;

  const ticketDetailsData = {
    labels: ["Total Login Time", "total Break Time"],
    datasets: [
      {
        label: "# of Votes",
        data: [40, 50],
        backgroundColor: ["#FFC2C7", "#0E86D4"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    cutoutPercentage: 50,
  };

  const sclaeoptions = {
    scales: {
      x: {
        grid: {
          offset: true,
        },
      },
    },
  };
  const kycIssue = {
    labels: [
      "Reset Link",
      "Reset Link",
      "Reset Link",
      "Reset Link",
      "Reset Link",
    ],
    datasets: [
      {
        label: "Pending",
        data: [65, 59, 80, 81, 56],
        backgroundColor: "#8EA7E9",
        borderWidth: 1,
      },
      {
        label: "solved",
        data: [25, 34, 20, 54, 14],
        backgroundColor: "#FAD93D",
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <FilterComponent
        filterData={filterData}
        handleCreatedFrom={handleFromDate}
        handleCreatedTo={handleToDate}
        handleFilter={handleFilter}
      />
      {/* Performance and top agent section */}

      <Grid container spacing={2} marginTop="2px" justifyContent="center">
        {/* CallReport */}
        <CallReportsSection />
        {/* Agent Activity */}
      </Grid>
      {/* SLA  */}
    </>
  );
};

export default AgentSummaryReport;

import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";

const DatePicker = ({ label, value, handleChange, name }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label={label}
          inputFormat="MM/DD/YYYY"
          value={value}
          name={name}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default DatePicker;

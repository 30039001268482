import React from "react";
import { Box, Button } from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  FirstPage,
  LastPage,
} from "@mui/icons-material";

const CustomPagination = ({
  currentPage,
  totalPage,
  handlePageClick,
  handleLeftClick,
  handleRightClick,
  handleFirstPageClick,
  handleLastPageClick,
}) => {
  //no of visible pages
  const maxVisiblePages = 5;

  // Calculate the start page number
  let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  // Calculate the end page number based on start page
  let endPage = startPage + maxVisiblePages - 1;

  // Adjust the start and end page if the end page exceeds the total pages
  if (endPage > totalPage) {
    endPage = totalPage;
    // Ensure to always show the same number of pages when possible
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  // Generate the array of page numbers to be displayed
  const pageNumbers = Array.from(
    { length: Math.min(maxVisiblePages, totalPage) },
    (_, i) => i + startPage
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop=".5rem"
      fontSize="12px"
      gap="4px"
    >
      <FirstPage
        sx={{
          color: currentPage === 1 ? "#d3d3d3" : "black",
          fontSize: "20px",
          cursor: "pointer",
        }}
        onClick={currentPage > 1 ? handleFirstPageClick : undefined}
      />
      <KeyboardArrowLeft
        sx={{
          color: currentPage === 1 ? "#d3d3d3" : "black",
          fontSize: "20px",
          cursor: "pointer",
        }}
        onClick={currentPage > 1 ? handleLeftClick : undefined}
      />
      {pageNumbers.map((page) => (
        <Button
          key={page}
          sx={{
            minWidth: "30px",
            padding: "4px 8px",
            color: currentPage === page ? "primary.main" : "black",
            backgroundColor:
              currentPage === page ? "primary.light" : "transparent",
            "&:hover": {
              backgroundColor:
                currentPage === page ? "primary.light" : "#f0f0f0",
            },
            fontSize: "14px",
            cursor: "pointer",
          }}
          onClick={() => handlePageClick(page)}
          disabled={currentPage === page}
        >
          {page}
        </Button>
      ))}
      <KeyboardArrowRight
        sx={{
          color: currentPage === totalPage ? "#d3d3d3" : "black",
          fontSize: "20px",
          cursor: "pointer",
        }}
        onClick={currentPage < totalPage ? handleRightClick : undefined}
      />
      <LastPage
        sx={{
          color: currentPage === totalPage ? "#d3d3d3" : "black",
          fontSize: "20px",
          cursor: "pointer",
        }}
        onClick={currentPage < totalPage ? handleLastPageClick : undefined}
      />
    </Box>
  );
};

export default CustomPagination;

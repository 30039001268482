import React, { useState } from "react";
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Button,
} from "@mui/material";

const SelectAgentModal = ({
  modaldata,
  handlemodalclose,
  agentData,
  setSelectedAgentId,
  onApiCallButtonClick,
}) => {
  const [selected, setSelected] = useState(null);

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const handleSelection = (extension) => {
    setSelected(extension);
    setSelectedAgentId(extension);
    // Send the extension back to the parent
  };

  return (
    <>
      <Modal
        keepMounted
        open={modaldata}
        onClose={handlemodalclose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              marginLeft: "3rem",
              marginTop: "2rem",
              height: "55vh",
              width: "95%",
              marginBottom: "2rem",
            }}
          >
            <Paper style={{ maxHeight: "50vh", overflow: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ backgroundColor: "#ECF2FF", color: "#000000" }}
                      padding="checkbox"
                    ></TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#ECF2FF", color: "#000000" }}
                    >
                      First Name
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#ECF2FF", color: "#00000" }}
                    >
                      Extension
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agentData?.map((agent) => (
                    <TableRow key={agent.extension}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected === agent.extension}
                          onChange={() => handleSelection(agent.extension)}
                        />
                      </TableCell>
                      <TableCell>{agent.firstName}</TableCell>
                      <TableCell>{agent.extension}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "2rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onApiCallButtonClick}
              >
                Assign Agent
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SelectAgentModal;

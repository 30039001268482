import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import allReducers from "./allReducers";


const middleware = [promise, thunk];
const initialState = {};


export default createStore(
    allReducers,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const NotificationModal = ({
  open,
  handleClose,
  notifications,
  onSelectMessage,
}) => {
  const [selectedNotification, setSelectedNotification] = useState("");
  const [isCustomMessage, setIsCustomMessage] = useState(false);
  const [customMessage, setCustomMessage] = useState("");

  const handleSelectChange = (event) => {
    setSelectedNotification(event.target.value);
    setCustomMessage("");
  };
  const handleCustomMessageChange = (event) => {
    setCustomMessage(event.target.value);
  };

  const handleSelectClick = () => {
    const message = isCustomMessage ? customMessage : notifications.find(
      (n) => n._id === selectedNotification
    )?.message;
    onSelectMessage(message);
    handleClose();
  };

  const toggleCustomMessage = () => {
    setIsCustomMessage(!isCustomMessage);
    setSelectedNotification("");
    setCustomMessage("");
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {isCustomMessage ? "Write Custom Message" : "Select Message"}
        </Typography>

        {isCustomMessage ? (
          <TextField
            fullWidth
            sx={{ marginTop: 1 }}
            variant="outlined"
            multiline
            placeholder="Write your custom message"
            value={customMessage}
            onChange={handleCustomMessageChange}
          />
        ) : (
          <>
            <Box display="flex" marginTop="15px" flexDirection="column">
              <Typography variant="span" fontSize="15px">
                Title
              </Typography>
            </Box>
            <Select
              fullWidth
              sx={{ marginTop: "0.5rem" }}
              value={selectedNotification}
              onChange={handleSelectChange}
            >
              {notifications?.map((notification) => (
                <MenuItem key={notification._id} value={notification._id}>
                  {notification.title}
                </MenuItem>
              ))}
            </Select>
            <Box display="flex" marginTop="15px" flexDirection="column">
              <Typography variant="span" fontSize="15px">
                Message
              </Typography>
              <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                variant="outlined"
                multiline // For multiline text
                value={
                  notifications?.find((n) => n._id === selectedNotification)
                    ?.message
                }
                InputProps={{
                  readOnly: true, // This makes the TextField read-only
                }}
              />

            </Box>
          </>
        )}

        <Box display="flex" justifyContent="center" marginTop="1em">
          <Button
            size="small"
            onClick={toggleCustomMessage}
          >
            {isCustomMessage ? "choose existing message" : "create custom message"}
          </Button>
        </Box>

        <Box display="flex" justifyContent="flex-end" marginTop="1em">
          <Button variant="contained" onClick={handleSelectClick}>
            Select Message
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NotificationModal;

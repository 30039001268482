import { FETCHED_CALL_LOGS } from './callActionTypes'

const init = {
  allCallLogs: [],
  pagination: ''
}

const callReducer = (state = init, action) => {
  const { type, payload } = action

  switch (type) {
    case FETCHED_CALL_LOGS:
      return {
        ...state,
        allCallLogs: payload?.callLogs,
        pagination: payload?.pagination
      }
    default:
      return state
  }
}

export default callReducer

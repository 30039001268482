import React from "react";
import { Box, Typography, Modal, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const BreakEndModal = ({
  openmodal,
  handleendmodalclose,
  handledbuttonsubmit,
}) => {
  return (
    <Modal keepMounted open={openmodal} onClose={handleendmodalclose}>
      <Box sx={style}>
        <Typography id="keep-mounted-modal-title" variant="h6">
          Do you want to end your break ?
        </Typography>
        <br />

        <br />
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          alignItems="center"
          gap="20px"
        >
          <Box display="flex">
            <Button onClick={handledbuttonsubmit} variant="contained">
              Confirm
            </Button>
          </Box>
          <Box display="flex">
            <Button onClick={handleendmodalclose} variant="contained">
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default BreakEndModal;

import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "../../App.scss";
import AppRoutes from "app/routes";

import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

const CallIndicator = () => {
  const [callState, setCallState] = React.useState(0);

  const [callDrawerActive, setcallDrawerActive] = React.useState(false);

  const handleCallDrawer = () => {
    if (callDrawerActive === false) {
      document.getElementsByClassName("content-wrapper")[0].style.width =
        "calc(100% - 300px)";
      document.getElementsByClassName("pageContainer")[0].style.width = "300px";
      document.getElementsByClassName("default-layout-navbar")[0].style.width =
        "calc(100% - 300px)";

      setcallDrawerActive(true);
    } else {
      document.getElementsByClassName("content-wrapper")[0].style.width =
        "100%";
      document.getElementsByClassName("pageContainer")[0].style.width = "0";
      document.getElementsByClassName("default-layout-navbar")[0].style.width =
        "100%";
      setcallDrawerActive(false);
    }
  };

  return (
    <>
      {/* <div className="call-container-absolute">
        <div
          onClick={handleCallDrawer}
          className={`call-container ${
            callState === 0
              ? "bg-orange"
              : callState === 1
              ? "bg-green pulse"
              : "bg-green call-container-calling"
          }`}
        >
          <i className="mdi mdi-phone-hangup"></i>
        </div>
      </div> */}
    </>
  );
};
const App = () => {
  return (
    <>
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        {/* <Sidebar /> */}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes />
            <CallIndicator />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const LeedAssignedData = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "CustomerName",
      headerName: "Customer Name",
      width: 200,
    },
    {
      field: "CustomerNumber",
      headerName: "Customer Number",
      width: 200,
    },
    {
      field: "AssignedAgent",
      headerName: "Assigned Agent",
      width: 200,
    },
  ];

  const rows = [
    {
      id: 1,

      CustomerName: "Jon doh",
      CustomerNumber: "9860098166",
      AssignedAgent: "2002",
    },
    {
      id: 2,

      CustomerName: "Ammosh shrestha",
      CustomerNumber: "986058166",
      AssignedAgent: "2005",
    },
    {
      id: 3,
      lastName: "Snow",
      CustomerName: "Hari Shretsha",
      CustomerNumber: "9860098166",
      AssignedAgent: "2006",
    },
    {
      id: 4,
      lastName: "Snow",
      CustomerName: "Jujjwal Puydasa",
      CustomerNumber: "9860098166",
      AssignedAgent: "2008",
    },
    {
      id: 5,
      lastName: "Snow",
      CustomerName: "praful thapa",
      CustomerNumber: "9860098166",
      AssignedAgent: "1555",
    },
  ];
  return (
    <>
      <Box
        sx={{
          marginLeft: "3rem",
          marginTop: "2rem",
          height: "55vh",
          width: "95%",
          marginBottom: "2rem",
        }}
      >
        <DataGrid rows={rows} columns={columns} hideFooter="true" />
      </Box>
    </>
  );
};

export default LeedAssignedData;

import React, { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";

import { Box } from "@mui/system";
import {
  Call,
  CallEnd,
  PhoneDisabled,
  PhoneInTalk,
  RingVolume,
} from "@mui/icons-material";
import { socketURL } from "app/utils/axiosRequest";

const AgentStatus = ({ userExtension }) => {
  const [status, setStatus] = useState("Idle");
  // Socket
  useEffect(() => {
    // console.log(`queuemember-status@${userExtensionNo}`);
    const accessToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(socketURL.liveUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (userExtension) {
      socket.on(`queuemember-status@${userExtension}`, (data) => {
        if (data) {
        }
      });
      socket.on(`extension-status@${userExtension}`, (data) => {
        if (data) {
          setStatus(data?.statustext);
        }
      });
    }
  }, []);

  return (
    <Box display="flex" alignItems="center" margin="0 20px">
      {status === "Idle" ? (
        <CallEnd />
      ) : status === "Ringing" ? (
        <RingVolume sx={{ color: "#81B622" }} />
      ) : status === "Inuse" ? (
        <PhoneInTalk sx={{ color: "#145DA0" }} />
      ) : (
        <PhoneDisabled sx={{ color: "#DF362D" }} />
      )}
    </Box>
  );
};

export default AgentStatus;

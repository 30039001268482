import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Button } from "@mui/material";
import CommonReportFilter from "app/pages/AdminDashboard/CommonReportFilter";
import dayjs from "dayjs";
import { Download } from "@mui/icons-material";
import BreakDetailsTable from "./BreakDetailsTable";
import { instance } from "app/utils/axiosRequest";
import { useQuery } from "@tanstack/react-query";

import { adminBreakReport } from "app/utils/api-urls/AdminAPiUrls";

const BreakDetails = () => {
  const [filterData, setFilterData] = useState({
    extension: "",
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    breakCategory: "",
    page: 1,
  });

  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    ["admin-break-detail"],
    () => instance.post(adminBreakReport.break_report, filterData)
  );

  useEffect(() => {
    data && console.log(data);
  }, [data]);

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleClear = () => {
    setFilterData({
      ...filterData,
      extension: "",
      from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
      breakCategory: "all",
    });
  };

  const handleFilter = () => {
    refetch();
  };

  //pagination left click
  const handleLeftClick = () => {
    setFilterData({
      ...filterData,
      page: --filterData.page,
    });
    refetch();
  };
  //pagination right click
  const handleRightClick = () => {
    setFilterData({
      ...filterData,
      page: ++filterData.page,
    });
    refetch();
  };

  const breakcategory = [
    {
      id: 1,
      value: "all",
      label: "All",
    },
    {
      id: 2,
      value: "teaBreak",
      label: "Tea Break",
    },
    {
      id: 3,
      value: "restroomBreak",
      label: "Restroom Break",
    },
    {
      id: 4,
      value: "lunchBreak",
      label: "Lunch Break",
    },
    {
      id: 5,
      value: "meetingBreak",
      label: "Meeting Break",
    },
    {
      id: 6,
      value: "ticketClosingBreak",
      label: "Ticket Closing Break",
    },
  ];

  return (
    <>
      <Box display="flex" gap="10px" width="100%" marginTop="1em">
        <Card sx={{ width: "25%" }}>
          <CardContent>
            <CommonReportFilter
              filterData={filterData}
              handleChange={handleChange}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              handleClear={handleClear}
              handleFilter={handleFilter}
              breakCategoryOptions={breakcategory}
              reportType="BreakDetails"
              isLoading={isRefetching}
            />
          </CardContent>
        </Card>
        <Box width="75%">
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="flex-end" marginBottom="10px">
                <Button variant="contained" startIcon={<Download />}>
                  Download
                </Button>
              </Box>
              <BreakDetailsTable
                isLoading={isLoading}
                tableData={data?.data}
                handleLeftClick={handleLeftClick}
                handleRightClick={handleRightClick}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default BreakDetails;

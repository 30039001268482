import axiosRequest from "app/utils/axiosRequest";
import { TAXONOMY_VALUE_FETCHED } from "./taxonomyActionType";

// import { addMessage } from "app/store/commonReducers";
// import { USERS_FETCHED,  } from "./UsersActionTypes";

const ENDPOINT_TAXONOMY = "v1/taxonomy";

export const getAllTaxonomy = async (dispatch) => {
  // return await axiosRequest
  //   .get(ENDPOINT_TAXONOMY)
  //   .finally(() => dispatch({ type: "DEACTIVATE_LOADING" }))
  //   .then((res) => {
  //     if (res.data) {
  //       // dispatch({ type: USERS_FETCHED, payload: res.data });
  //     }
  //   })
  //   .catch((err) => {});
};
export const getTaxonomyValue = (taxName) => async (dispatch) => {
  // return await axiosRequest
  //   .get(`${ENDPOINT_TAXONOMY}/getValues/${taxName}`)
  //   .then((res) => {
  //     if (res.data.status === "success") {
  //       const values = res.data.data.taxonomy;
  //       dispatch({
  //         type: TAXONOMY_VALUE_FETCHED,
  //         payload: { data: values, taxName },
  //       });
  //     }
  //   })
  //   .catch((err) => {})
  //   .finally(() => dispatch({ type: "DEACTIVATE_LOADING" }));
};
// export const getTaxonomyById = (params) => async (dispatch) => {
//
//   return await axiosRequest
//     .get(ENDPOINT_FETCH_USER)
//     .finally(() => dispatch({ type: "DEACTIVATE_LOADING" }))
//     .then((res) => {
//       if (res.data) {
//         //   dispatch({ type: USERS_FETCHED, payload: res.data });
//       }
//     })
//     .catch((err) => {});
// };

import React, { Component, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { performUserLoginAction } from "app/store/auth/authAction";
import LOGO from "../../../assets/logo/LOGO.png";
import logo from "../../../assets/logo/logo.jpg";
import nasaLogo from "../../../assets/images/nasa.jpg";
import wft from "../../../assets/images/wft.png";
import IconButton from "@mui/material/IconButton";

// import Footer from "app/shared/Footer";
import { isUserLogged } from "../../utils";
import { Box, Button, TextField, Typography } from "@mui/material";
import SnackbarComponent from "app/components/SnackBar";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  DraftsOutlined,
  VisibilityOffOutlined,
  VisibilitySharp,
} from "@mui/icons-material";

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [hideLogin, setHideLogin] = useState(false);
  const [isServer, setIsServer] = useState();
  const [loginDetails, setLoginDetails] = useState({
    extension: "",
    password: "",
    showPassword: false,
  });

  const [snackbarData, setSnackbarData] = useState({
    show: false,
    severity: "error",
    message: "",
  });

  React.useEffect(() => {
    if (isUserLogged()) {
      history.push("/app/dashboard");
    }
  }, []);

  React.useEffect(() => {
    let uri = new URL(window.location);
    uri.host === "support.naasasecurities.com.np"
      ? setIsServer(<img src={nasaLogo} alt="logo" style={{ maxWidth: 250 }} />)
      : setIsServer(
          <Typography sx={{ color: "#002B5B" }} variant="h4">
            Connect Infinity
          </Typography>
        );
  }, []);

  const handleLoginChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setLoginDetails({
      ...loginDetails,
      showPassword: !loginDetails.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    try {
      let loginData = await performUserLoginAction(dispatch, loginDetails);

      if (loginData?.access_token) {
        setTimeout(function () {
          window.open(`/app/dashboard`, "_self");
        }, 1000);

        setSnackbarData({
          show: true,
          severity: "success",
          message: "login successfully",
        }); //me api call here on ime
      }
    } catch (err) {
      setSnackbarData({
        show: true,
        severity: "error",
        message: "Please enter valid login credentials",
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarData({ ...snackbarData, show: false });
  };

  return (
    <div>
      <SnackbarComponent
        data={snackbarData}
        open={snackbarData.show}
        handleClose={handleSnackbarClose}
      />
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-5 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              {hideLogin ? (
                {
                  /* <ForgotPassword hideView={() => setHideLogin(false)} /> */
                }
              ) : (
                <div>
                  <div className="d-flex align-items-center flex-column">
                    <div className="mb-4">{isServer}</div>
                    <h6 className="font-weight-dark">Sign in </h6>
                  </div>

                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                    alignItems="center"
                  >
                    <TextField
                      label="Extension Number"
                      size="small"
                      fullWidth
                      variant="outlined"
                      name="extension"
                      value={loginDetails.extension}
                      onChange={handleLoginChange}
                    />
                    <TextField
                      label="Password"
                      size="small"
                      fullWidth
                      variant="outlined"
                      name="password"
                      type={loginDetails.showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            position="end"
                          >
                            {loginDetails.showPassword ? (
                              <VisibilityOff sx={{ cursor: "pointer" }} />
                            ) : (
                              <Visibility sx={{ cursor: "pointer" }} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      value={loginDetails.password}
                      onChange={handleLoginChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleLogin();
                        }
                      }}
                    />
                    <Button variant="contained" fullWidth onClick={handleLogin}>
                      Sign In
                    </Button>
                  </Box>

                  <div className="my-2 d-flex justify-content-between align-items-center">
                    {/* <div className="form-check"></div> */}
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      my: "4",
                      marginTop: "3rem",
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="#00235B"
                      fontSize="0.8rem"
                      fontWeight="4px"
                      fontStyle="italic
                      "
                    >
                      Driven By
                    </Typography>
                    <img src={wft} alt="logo" style={{ maxWidth: 90 }} />
                  </Box>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

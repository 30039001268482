import createCampaign from "app/pages/outbundCampaign/createCampaign";

const outboundCampaignRoutes = [
  {
    path: "/app/admin/outbound-campaign",
    shortPath: "/admin/outbound-campaign",
    name: "Create Outbound Campaign",
    component: createCampaign,
    icon: "phone-plus",
    auth: ["admin", "admin"],
  },
];
export default outboundCampaignRoutes;

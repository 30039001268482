//Admin_Ticket_List

export const adminTicketList = {
  ticket_list: "/admin/report/ticket",
  assigned_to_me: "/admin/report/assigned-ticket",
};

//Call-history-Report

export const adminCallHistoryReport = {
  callhistory_list: "admin/report/call",
};

//Hold_time_report

export const adminQueueReport = {
  Queue_report: "admin/report/queue",
};

//all-queue-list

export const allQueueList = {
  allqueue_list: "admin/queues",
};

// Break Detail Report

export const adminBreakReport = {
  break_report: "extension/get-break-report",
};

//Queue_report

export const adminqueueReport = {
  queue_report: "/queue/report",
};

//extension_List

export const extensionList = {
  extension_list: "admin/users",
};

//all Category-list

export const allcategoryList = {
  category_list: "admin/issue",
};

//all sub category list

export const allsubcategoryList = {
  sub_category_list: "category/sub-issue",
};

// update ticket //TODO change agent to

export const updateTicket = {
  update_ticket: "agent/ticket",
};

export const updateAdminTicket = {
  update_Admin_ticket: "admin/ticket",
};

export const admindashboardStats = {
  dashboard_stats: "admin/stats/dashboard",
};

export const addIssueCategory = {
  issue_category: "admin/issue",
};

export const addSubIssueCategory = {
  Sub_issue_category: "admin/subissue",
};

//to listen recording
export const listenRecording = {
  listen_recording: "admin/recording/call",
};

export const adminlistenVoicemail = {
  admin_voicemail_recording: "admin/recording/voicemail",
};

//to fetch all agent

export const getAllAgent = {
  get_all_agent: "/admin/agents",
};

//to fetch email template for setting

export const getEmailTemplate = {
  get_email_template: "/admin/template",
};

export const queueCallLogs = {
  queue_call_logs: "admin/report/queue-logs",
};

export const breakCategory = {
  break_category: "admin/breaks",
  extension_pause: "admin/break",
  extension_status: "admin/extension-status",
};

// outbound campaign
//TODO api/v1 and no auth implemenatation

export const addregistercampaign = {
  add_register_campaign: "/outbound-campaign/register",
};

export const getregisterCampaignByIdForTable = {
  get_register_CampaignBy_Id_ForTable: "/outbound-campaign/registered-campaign",
};

export const uploadLeeds = {
  upload_leeds: "/outbound-campaign/add-clients",
};

export const getRegisteredClients = {
  get_registered_client: "/outbound-campaign/client-list",
};

export const assignAgentExtension = {
  assign_Agent: "/outbound-campaign/assign-extension",
};

//to listen recording for ticket
export const adminlistenRecording = {
  admin_listen_recording: "admin/recording/ticket",
};

//to update ticet status,remarks
export const adminupdateTicketStatus = {
  admin_update_ticket_status: "admin/solve-ticket",
};

export const adminAssignTicket = {
  admin_assigned_ticket: "admin/assign-ticket",
};

export const adminAssignTicketDepartment = {
  admin_assigned_ticket_department: "admin/assign-ticket-department",
};

export const admindownloadTickets = {
  admin_download_tickets: "admin/download/report/ticket",
};

export const adminPickTicketApi = {
  admin_pick_ticket_api: "admin/ticket/pick-ticket",
};

export const adminBulkTickets = {
  admin_bulk_tickets: "admin/bulk-ticket",
};

import { Modal, Box, Typography, Button } from "@mui/material";

const ChatPreviewModal = ({ isOpen, onClose, chat }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box padding="20px" margin="50px auto" width="700px" bgcolor="white">
        <Typography variant="h6" marginBottom="20px">
          Chat Preview
        </Typography>

        <Box
          style={{
            maxHeight: "71vh",
            overflowY: "auto",
          }}
        >
          {chat &&
            chat.questions.map((question, index) => (
              <Box key={index} marginBottom="20px">
                <Typography variant="subtitle1" fontWeight="bold">
                  Client: {question}
                </Typography>
                <Typography
                  marginTop={"0.5rem"}
                  fontSize="17px"
                  variant="body2"
                >
                  Chatbot: {chat.answers[index]}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ChatPreviewModal;

import {
  USER_PROFILE_FETCHED,
  FETCH_USER,
  STORE_USER_PERMISSION,
  USER_LOGGED_IN,
  USER_LOGGED_OUT
} from './authActionType'

const init = {
  isLogged: null,
  profile: {},
  users: [],
  permissions: {}
}
const AuthReducer = (state = init, action) => {
  const { type, payload } = action
  let newState = {}
  switch (action.type) {
    case USER_PROFILE_FETCHED:
      return { ...state, profile: payload }
    case USER_LOGGED_IN:
      return { ...state, isLogged: true, profile: payload?.user }
    case USER_LOGGED_OUT:
      return { ...init, isLogged: false }
    case FETCH_USER:
      return newState
    default:
      return state
  }
}

export default AuthReducer

// import { axiosRequest } from "app/utils";
import { instance } from "app/utils/axiosRequest";
import Store from "../../store";

import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_PROFILE_FETCHED,
} from "./authActionType";

// const ENDPOINT_USER_LOGIN = "/extension/login";
const ENDPOINT_USER_LOGIN = "/auth/login";
const ENDPOINT_GET_USER = "/v1/users/getCredential";

//login
export const performUserLoginAction = async (dispatch, loginDetails) => {
  return await instance.post(ENDPOINT_USER_LOGIN, loginDetails).then((res) => {
    if (res?.data) {
      const token = res?.data?.access_token || {};

      localStorage.setItem("callCenterToken", token);
      localStorage.setItem("username", res?.data?.firstName);
      localStorage.setItem(
        "callCenterUser",
        JSON.stringify(res?.data?.extension)
      );

      localStorage.setItem("role", res?.data?.role);

      return Promise.resolve(res.data);
    } else {
      dispatch();
    }
  });
};

export const getUserProfileAction = async (dispatch, userId) => {};

export const performUserLogoutAction = (dispatch) => {
  localStorage.clear();
  dispatch({ type: USER_LOGGED_OUT });
};

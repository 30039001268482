import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import { instance } from "app/utils/axiosRequest";
import { useQuery } from "@tanstack/react-query";
import { socketURL } from "app/utils/axiosRequest";
import socketIOClient from "socket.io-client";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import {
  CallEnd,
  CallSplit,
  Phone,
  PhoneDisabled,
  PhoneEnabled,
  PhoneMissed,
  WindowSharp,
} from "@mui/icons-material";
import { Link, useHistory } from "react-router-dom";
import CallHistoryReport from "app/pages/AdminDashboard/callHistory";
import dayjs from "dayjs";
import qs from "qs";
import {
  adminCallHistoryReport,
  admindashboardStats,
} from "app/utils/api-urls/AdminAPiUrls";

const CallSummary = () => {
  const history = useHistory();

  const [adminDashboardData, setadminDashboardData] = useState({
    received: 0,
    inbound_success: 0,
    outbound_success: 0,
    outbound_missed: 0,
    internal_calls: 0,
    total_calls: 0,
    abandoned_calls: 0,
  });

  const { data, refetch, fetch, isLoading, isRefetching } = useQuery(
    ["Dashboard-summary-data-callreport"],
    () => instance.get(admindashboardStats.dashboard_stats),
    {
      onSuccess: (data) => {
        setadminDashboardData({
          received: data?.data?.call_stats?.inbound_calls,
          inbound_success: data?.data?.call_stats?.successful_inbound_calls,
          outbound_success: data?.data?.call_stats.successful_outbound_calls,
          outbound_missed: data?.data?.call_stats.unsuccessful_outbound_calls,
          internal_calls: data?.data?.call_stats?.internal_calls,
          total_calls: data?.data?.call_stats?.total_calls,
          abandoned_calls: data?.data?.call_stats?.abandoned_calls,
        });
      },
    }
  );

  //Fetch for total calls

  const [totalcalls, settotalcalls] = useState({
    extension: "",
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    type: "",
    phone: "",
    page: 1,
    limit: 20,
  });
  const { refetch: refetchtotalcalls } = useQuery(
    ["admin-callhistory-report-totalcalls", totalcalls],
    () =>
      instance.get(
        adminCallHistoryReport.callhistory_list + "?" + qs.stringify(totalcalls)
      )
  );

  const redirectTototalCallsSection = () => {
    history.push({
      pathname: "/app/admin/call-history",
    });
    refetchtotalcalls();
  };

  //Fetch for Success inbound calls
  const [inboundcalls, setinboundcalls] = useState({
    extension: "",
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    type: "inbound-success",
    phone: "",
    page: 1,
    limit: 20,
  });
  const { refetch: refetchinboundcalls } = useQuery(
    ["admin-callhistory-report-inboundcalls", inboundcalls],
    () =>
      instance.get(
        adminCallHistoryReport.callhistory_list +
          "?" +
          qs.stringify(inboundcalls)
      )
  );
  const redirectToInboundCalls = () => {
    history.push({
      pathname: "/app/admin/call-history",
    });
    refetchinboundcalls();
  };

  //fetch for abandoned calls
  const [abandoned, setabandoned] = useState({
    extension: "",
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    type: "inbound-missed",
    phone: "",
    page: 1,
    limit: 20,
  });
  const { refetch: refetchabandoned } = useQuery(
    ["admin-callhistory-report-abandoned", abandoned],
    () =>
      instance.get(
        adminCallHistoryReport.callhistory_list + "?" + qs.stringify(abandoned)
      )
  );
  const redirectToAbandonedCalls = () => {
    history.push({
      pathname: "/app/admin/call-history",
    });
    refetchabandoned();
  };

  //fetch for successoutbound calls
  const [successoutboundCalls, setSuccessoutboundCalls] = useState({
    extension: "",
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    status: "outbound-success",
    phone: "",
    page: 1,
    limit: 20,
  });
  const { refetch: refetchsuccessoutbound } = useQuery(
    ["admin-callhistory-report-successoutboundCalls", successoutboundCalls],
    () =>
      instance.get(
        adminCallHistoryReport.callhistory_list +
          "?" +
          qs.stringify(successoutboundCalls)
      )
  );
  const redirectTosuccessoutbound = () => {
    history.push({
      pathname: "/app/admin/call-history",
    });
    refetchsuccessoutbound();
  };
  //fetch for missedoutbound calls
  const [missedoutboundcalls, setmissedoutboundCalls] = useState({
    extension: "",
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    status: "outbound-missed",
    phone: "",
    page: 1,
    limit: 20,
  });
  const { refetch: refetchmissedoutbound } = useQuery(
    ["admin-callhistory-report-missedoutboundcalls", missedoutboundcalls],
    () =>
      instance.get(
        adminCallHistoryReport.callhistory_list +
          "?" +
          qs.stringify(missedoutboundcalls)
      )
  );
  const redirectTomissedoutbound = () => {
    history.push({
      pathname: "/app/admin/call-history",
    });
    refetchmissedoutbound();
  };

  // useEffect(() => {
  //   const accessToken = localStorage.getItem("callCenterToken");
  //   const socket = socketIOClient(socketURL.liveUrl, {
  //     extraHeaders: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   });
  //   socket.on("call-received", (received) => {
  //     console.log(received);
  //     setadminDashboardData((initialState) => ({
  //       ...initialState,
  //       received: initialState.received + 1,
  //       total_calls: initialState.total_calls + 1,
  //     }));
  //   });

  //   socket.on("queue-leave", (leave) => {
  //     setadminDashboardData((initialState) => ({
  //       ...initialState,
  //       abandoned_calls: initialState.abandoned_calls + 1,
  //       total_calls: initialState.total_calls + 1,
  //     }));
  //   });

  //   socket.on("outbound-missed", (outmissed) => {
  //     setadminDashboardData((initialState) => ({
  //       ...initialState,
  //       outbound_missed: initialState.outbound_missed + 1,
  //       total_calls: initialState.total_calls + 1,
  //     }));
  //   });

  //   socket.on("outbound-success", (success) => {
  //     setadminDashboardData((initialState) => ({
  //       ...initialState,
  //       outbound_success: initialState.outbound_success + 1,
  //       total_calls: initialState.total_calls + 1,
  //     }));
  //   });

  //   socket.on("internal-call", (leave) => {
  //     setadminDashboardData((initialState) => ({
  //       ...initialState,
  //       internal_call: initialState.internal_calls + 1,
  //       total_calls: initialState.total_calls + 1,
  //     }));
  //   });
  // }, []);

  return (
    <>
      <FullpageLoader open={isLoading} />
      <Grid item xs={6} md={2}>
        <Card sx={{ height: "100%" }}>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // ":hover": {
              //   backgroundColor: "#ECF2FF",
              //   cursor: "pointer",
              // },
            }}
            // onClick={(evt) => {
            //   evt.preventDefault();
            //   redirectTototalCallsSection();
            // }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="span" fontSize="25px" color="#2C74B3">
                {adminDashboardData?.total_calls}
              </Typography>
              <Typography variant="span" fontSize="14px">
                Total Calls
              </Typography>
            </Box>
            <CallEnd sx={{ fontSize: "30px", color: "#2C74B3" }} />
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item xs={6} md={2}>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // ":hover": {
              //   backgroundColor: "#ECF2FF",
              //   cursor: "pointer",
              // },
            }}
            // onClick={(evt) => {
            //   evt.preventDefault();
            //   redirectToAbandonedCalls();
            // }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="span" fontSize="25px" color="#2C74B3">
                {adminDashboardData?.received}
              </Typography>
              <Typography variant="span" fontSize="14px">
                Total Inbound Calls
              </Typography>
            </Box>
            <PhoneEnabled sx={{ fontSize: "30px", color: "#2C74B3" }} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={2}>
        <Card height="100%">
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // ":hover": {
              //   backgroundColor: "#ECF2FF",
              //   cursor: "pointer",
              // },
            }}
            // onClick={(evt) => {
            //   evt.preventDefault();
            //   redirectToInboundCalls();
            // }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="span" fontSize="25px" color="#4E9F3D">
                {adminDashboardData?.inbound_success}
              </Typography>
              <Typography variant="span" fontSize="14px">
                Success Inbound calls
              </Typography>
            </Box>
            <PhoneEnabled sx={{ fontSize: "30px", color: "#4E9F3D" }} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={2}>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // ":hover": {
              //   backgroundColor: "#ECF2FF",
              //   cursor: "pointer",
              // },
            }}
            // onClick={(evt) => {
            //   evt.preventDefault();
            //   redirectToAbandonedCalls();
            // }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="span" fontSize="25px" color="#FF0000">
                {adminDashboardData?.abandoned_calls}
              </Typography>
              <Typography variant="span" fontSize="14px">
                Abandoned Calls
              </Typography>
            </Box>
            <PhoneEnabled sx={{ fontSize: "30px", color: "#FF0000" }} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={2}>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // ":hover": {
              //   backgroundColor: "#ECF2FF",
              //   cursor: "pointer",
              // },
            }}
            // onClick={(evt) => {
            //   evt.preventDefault();
            //   redirectTosuccessoutbound();
            // }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="span" fontSize="25px" color="#4E9F3D">
                {adminDashboardData?.outbound_success}
              </Typography>
              <Typography variant="span" fontSize="14px">
                Success Outbound Calls
              </Typography>
            </Box>
            <Phone sx={{ fontSize: "30px", color: "#4E9F3D" }} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={2}>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // ":hover": {
              //   backgroundColor: "#ECF2FF",
              //   cursor: "pointer",
              // },
            }}
            // onClick={(evt) => {
            //   evt.preventDefault();
            //   redirectTomissedoutbound();
            // }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="span" fontSize="25px" color="#FF0000">
                {adminDashboardData?.outbound_missed}
              </Typography>

              <Typography variant="span" fontSize="14px">
                Missed Outbound Calls
              </Typography>
            </Box>
            <PhoneMissed sx={{ fontSize: "30px", color: "#FF0000" }} />
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item xs={6} md={2}>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="span" fontSize="25px" color="#2C74B3">
                {adminDashboardData?.internal_calls}
              </Typography>
              <Typography variant="span" fontSize="14px">
                Internal Calls
              </Typography>
            </Box>
            <CallSplit sx={{ fontSize: "30px", color: "#2C74B3" }} />
          </CardContent>
        </Card>
      </Grid> */}
    </>
  );
};

export default CallSummary;

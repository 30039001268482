// import { lazy } from "react";

import BreakCategory from "app/pages/Setting/BreakCategory/BreakCategory";
import IssueCategory from "app/pages/Setting/IssueCategory";
import Knowledgebase from "app/pages/Setting/KnowledgeBase/knowledgebase";
import Template from "app/pages/Setting/Template/Template";
import UserSetting from "app/pages/Setting/user/userSetting";

const setupRoutes = [
  {
    path: "/app/setup/issue-category",
    shortPath: "/setup/issue-category",
    name: "Issue Category",
    component: IssueCategory,
    icon: "mdi mdi-shape",
  },
  {
    path: "/app/setup/template",
    shortPath: "/setup/template",
    name: "Setup Template",
    component: Template,
    icon: "mdi mdi-email",
  },
  {
    path: "/app/setup/break-category",
    shortPath: "/setup/break-cateory",
    name: "Break Category",
    component: BreakCategory,
    icon: "mdi mdi-clock",
  },

  // {
  //   path: "/app/setup/create-user",
  //   shortPath: "/setup/create-user",
  //   name: "User Setup",
  //   component: UserSetting,
  //   icon: "mdi mdi-account-circle",
  // },
  // {
  //   path: "/app/setup/knowledge-base",
  //   shortPath: "/setup/knowledge-base",
  //   name: "knowledge Base",
  //   component: Knowledgebase,
  //   icon: "mdi mdi-information",
  // },
  // {
  //   path: "/app/setup/setup-permissions",
  //   shortPath: "/setup/setup-permissions",
  //   name: "Setup permissions",
  //   component: CreatePermissions,
  //   icon: "mdi mdi-account-multiple-check",
  // },
];

export default setupRoutes;

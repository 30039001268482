import { Box, Button, Typography } from "@mui/material";
import React from "react";
import LeedAssignedData from "./LeedAssignedData";
import { ArrowBack } from "@mui/icons-material";

const ViewCampaign = () => {
  return (
    <>
      <Box justifyContent="flex-start" display="flex" flexDirection="column">
        {/* Title Section */}
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            marginLeft: "2rem",
          }}
        >
          <Button variant="text" startIcon={<ArrowBack />}>
            Back
          </Button>

          <Typography
            variant="h8"
            sx={{
              fontSize: "1.5rem",
              letterSpacing: "0.2rem",
              marginLeft: "2rem",
            }}
          >
            Campaign Name
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontSize: "1.2rem" /* Decreased font size for the date */,
              letterSpacing: "0.1rem",
              fontStyle: "italic",
              opacity: 0.6,
              marginTop: "0.2rem",
            }}
          >
            2072-01-02 -
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontSize: "1.2rem" /* Decreased font size for the date */,
              letterSpacing: "0.1rem",
              fontStyle: "italic",
              opacity: 0.6,
              marginTop: "0.2rem",
            }}
          >
            2072-01-10
          </Typography>
        </Box>
        {/* //TableData */}
        <LeedAssignedData />
      </Box>
    </>
  );
};

export default ViewCampaign;

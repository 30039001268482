import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  addIssueCategory,
  addSubIssueCategory,
} from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import { useMutation } from "@tanstack/react-query";

const AddSubIssueCategory = ({
  open,
  refetch,
  handleClose,
  issueCategoryData,
}) => {
  const [subIssueName, setSubIssueName] = useState("");

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const SubIssuemutation = useMutation(
    ["post-sub-issue-category"],
    (payload) => instance.post(addSubIssueCategory.Sub_issue_category, payload),
    {
      onSuccess: (response) => {
        const newSubIssue = response.data;
        const payloadForSecondMutation = {
          id: newSubIssue._id,
        };
        mutation.mutate(payloadForSecondMutation);
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error adding sub-issue!",
        });
      },
    }
  );

  const mutation = useMutation(
    ["post-sub-issue-category-to-issue"],
    (payload) => {
      const url = `${addIssueCategory.issue_category}/${issueCategoryData.id}/add-subissue`;
      return instance.patch(url, payload);
    },
    {
      onSuccess: () => {
        refetch();
        handleClose();
        setSnackBar({
          open: true,
          severity: "success",
          message: "Sub-issue added successfully!",
        });
        setSubIssueName("");
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error adding template!",
        });
      },
    }
  );

  const isSubmitting = SubIssuemutation.isLoading || mutation.isLoading;
  const handleSubmit = () => {
    const payload = {
      name: subIssueName,
    };
    SubIssuemutation.mutate(payload);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            marginBottom={2}
          >
            Add Sub Issue to: {issueCategoryData.name}
          </Typography>
          <FormControl fullWidth>
            <TextField
              label="Sub Issue Name"
              value={subIssueName}
              onChange={(e) => setSubIssueName(e.target.value)}
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ marginTop: 16 }}
              disabled={isSubmitting || !subIssueName.trim()} // Disable if loading or subIssueName is empty
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

export default AddSubIssueCategory;

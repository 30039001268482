import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import React from "react";

const LogReportTable = ({
  isLoading,
  tableData,
  handleLeftClick,
  handleRightClick,
}) => {
  const columns = [
    {
      field: "extension",
      headerName: "Agent",
      width: 200,
    },

    {
      field: "date",
      headerName: "Login Date",
      width: 200,
      renderCell: (params) => {
        const array = params?.row?.loginTime;
        const timearr = array.split(",");
        const newtime = timearr[0];
        return <Typography>{newtime}</Typography>;
      },
    },

    {
      field: "LoginTime",
      headerName: "Login Time",
      width: 200,
      renderCell: (params) => {
        const array = params?.row?.loginTime;
        const timearr = array.split(",");
        const newtime = timearr[1];
        return <Typography>{newtime}</Typography>;
      },
    },
    {
      field: "LogoutTime",
      headerName: "Logout Time",
      width: 200,
      renderCell: (params) => {
        const array = params?.row?.logoutTime;
        const timearr = array.split(",");
        const newtime = timearr[1];
        return <Typography>{newtime}</Typography>;
      },
    },
    {
      field: "totalTime",
      headerName: " Total Time",
      width: 200,
    },
  ];

  const rows = [
    {
      id: 1,
      LoginStatus: "Logged In",
      createdAt: "2022-11-15",
      extension: "2000",
      date: "05 Sep 2022 ",
      LogInTime: "10:45:00",
      LogOutTime: "10:50:00",
      totalTime: "00:05:00",
    },
    {
      id: 2,
      LoginStatus: "Logged Out",
      createdAt: "2022-11-15",
      extension: "2000",
      date: "05 Sep 2022 ",
      LogInTime: "10:45:00",
      LogOutTime: "10:50:00",
      totalTime: "00:05:00",
    },
    {
      id: 3,
      LoginStatus: "Logged In",
      createdAt: "2022-11-15",
      extension: "2000",
      date: "05 Sep 2022 ",
      LogInTime: "10:45:00",
      LogOutTime: "10:50:00",
      totalTime: "00:05:00",
    },
    {
      id: 4,
      LoginStatus: "Logged In",
      createdAt: "2022-11-15",
      extension: "2020",
      date: "05 Sep 2022 ",
      LogInTime: "10:45:00",
      LogOutTime: "10:50:00",
      totalTime: "00:05:00",
    },
    {
      id: 5,
      LoginStatus: "Logged Out",
      createdAt: "2022-11-15",
      extension: "2020",
      date: "05 Sep 2022 ",
      LogInTime: "10:45:00",
      LogOutTime: "10:50:00",
      totalTime: "00:05:00",
    },
  ];

  return (
    <Box width="100%" height="62vh">
      {!isLoading && tableData && (
        <>
          <DataGrid
            columns={columns}
            rows={tableData?.data}
            getRowId={(rows) => rows["updatedAt"]}
            hideFooter="true"
          />
          <CustomPagination
            currentPage={tableData?.currentPage}
            totalPage={tableData?.totalPages}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
          />
        </>
      )}
    </Box>
  );
};

export default LogReportTable;

import React, { useState } from "react";

import { Download } from "@mui/icons-material";
import { Box, Card, CardContent, Button } from "@mui/material";
import CommonReportFilter from "app/pages/AdminDashboard/CommonReportFilter";
import dayjs from "dayjs";
import IssueReportTable from "./IssueReportTable";

const IssueReports = () => {
  const [filterData, setFilterData] = useState({
    fromDate: dayjs().subtract(7, "days").format("MM/DD/YYYY"),
    toDate: dayjs().format("MM/DD/YYYY"),
    issueCategory: "all",
  });

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      fromDate: value,
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      toDate: value,
    });
  };

  const handleClear = () => {
    setFilterData({
      fromDate: dayjs().subtract(7, "days").format("MM/DD/YYYY"),
      toDate: dayjs().format("MM/DD/YYYY"),
      issueCategory: "all",
    });
  };

  const handleFilter = () => {
    console.log(filterData);
  };

  const issueCategories = [
    {
      id: 1,
      value: "all",
      label: "All",
    },
    {
      id: 2,
      value: "issue1",
      label: "Issue 1",
    },
    {
      id: 3,
      value: "issue2",
      label: "Issue 2",
    },
    {
      id: 4,
      value: "issue3",
      label: "Issue 3",
    },
  ];
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img
          alt="Comming Soon"
          src="/comming-soon.jpg"
          style={{
            height: "90vh",
            width: "90vw",
          }}
        />
      </Box>
      {/* <Box display="flex" gap="10px" width="100%" marginTop="1em">
        <Card sx={{ width: "25%" }}>
          <CardContent>
            <CommonReportFilter
              filterData={filterData}
              handleChange={handleChange}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              handleClear={handleClear}
              handleFilter={handleFilter}
              issueCategories={issueCategories}
              reportType="issueReports"
            />
          </CardContent>
        </Card>
        <Box width="75%">
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="flex-end" marginBottom="10px">
                <Button variant="contained" startIcon={<Download />}>
                  Download
                </Button>
              </Box>
              <IssueReportTable />
            </CardContent>
          </Card>
        </Box>
      </Box> */}
    </>
  );
};

export default IssueReports;

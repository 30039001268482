import { lazy } from "react";

/* reports */

const reportRoutes = [
  // {
  //   path: "/app/report/ticket",
  //   shortPath: "/report/ticket",
  //   name: "Ticket",
  //   icon: "mdi mdi-nfc-search-variant",
  //   component: TicketReports,
  // },
  // {
  //   path: "/app/report/faq",
  //   shortPath: "/report/faq",
  //   name: "FAQ",
  //   icon: "mdi mdi-comment-plus-outline",
  //   component: FaqReport,
  // },
  // {
  //   path: "/app/report/daily-activities/create",
  //   shortPath: "/report/daily-activities/create",
  //   name: "Create Daily Activities",
  //   component: CreateDailyActivities,
  //   icon: "mdi mdi-pen-remove",
  // },
  // {
  //   path: "/app/report/profile",
  //   shortPath: "/report/profile",
  //   name: "Employee Profile",
  //   icon: "mdi mdi-account-multiple",
  //   component: DailyActivities,
  // },
  // {
  //   path: "/app/report/vip-access",
  //   shortPath: "/report/vip-access",
  //   name: "Vip Access",
  //   icon: "mdi mdi-account-lock",
  //   component: VipAccessReports,
  // },
];

export default reportRoutes;

import { PERMISSION_FETCHED, ROlE_FETCHED } from "./permissionActionTypes";

const init = {
  allPermissions: [],
  allRoles: [],
};
const PermissionsReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case PERMISSION_FETCHED:
      return { ...state, allPermissions: payload?.data?.results };
    case ROlE_FETCHED:
      return { ...state, allRoles: payload?.data?.results };
    default:
      return state;
  }
};

export default PermissionsReducer;

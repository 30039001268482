import {
  FETCH_CLIENT_DETAILS,
  EMPTY_CLIENT_DETAILS
} from './clientDetailsActionTypes'

const init = {
  clientDetails: ''
}

const clientDetailsReducer = (state = init, action) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: payload
      }
    case EMPTY_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: payload
      }
    default:
      return state
  }
}

export default clientDetailsReducer

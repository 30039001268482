import { TAXONOMY_VALUE_FETCHED } from "./taxonomyActionType";

const init = {
  Ticket_Subjects: {},
  "FAQ-Group": {},
  ticketSubjectOptions: null,
  faqGroupOptions: null,
};
const TaxonomyReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case TAXONOMY_VALUE_FETCHED:
      const { data, taxName } = payload;
      let { ticketSubjectOptions, faqGroupOptions } = state;

      if (taxName === "FAQ-Group")
        faqGroupOptions =
          data?.values.map((d) => {
            return { label: d.name, value: d?._id };
          }) ?? [];
      if (taxName === "Ticket_Subjects")
        ticketSubjectOptions =
          data?.values.map((d) => {
            return { label: d?.name, value: d?._id };
          }) ?? [];
      return {
        ...state,
        [taxName]: data,
        ticketSubjectOptions,
        faqGroupOptions,
      };
    default:
      return state;
  }
};

export default TaxonomyReducer;

import { AddCircleOutlineOutlined, DeleteOutline } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import { allcategoryList } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import React, { useState } from "react";
import AddisueCategory from "./AddisueCategory";
import AddSubIssueCategory from "./AddSubIssueCategory";
import DeleteConfirmationDialog from "./DeleteSubissueDialog";
import DeleteIssueCategoryDialog from "./DeleteIssue";

const GetIssueCategory = () => {
  const [cardData, setCardData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIssueCategory, setSelectedIssueCategory] = useState({
    id: null,
    name: "",
  });
  const [isSubIssueModalOpen, setIsSubIssueModalOpen] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isIssueDeleteOpen, setisIssueDeleteOpen] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState({
    issueId: null,
    subIssueId: null,
  });

  const [selectedissue, setSelectedIssue] = useState(null);

  const handleAddIssueCategoryModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddSubIssueCategoryModalOpen = (id, name) => {
    setSelectedIssueCategory({ id, name });
    setIsSubIssueModalOpen(true);
  };

  const {
    data: issueCategory,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    ["admin-report-issue-categories"],
    () => instance.get(allcategoryList.category_list),
    {
      onSuccess: (data) => {
        setCardData(data?.data);
      },
    }
  );

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  };

  const cardDataChunks = chunkArray(cardData, 3);

  return (
    <>
      <AddisueCategory
        open={isModalOpen}
        handleClose={handleAddIssueCategoryModalOpen}
        refetch={refetch}
      />
      <AddSubIssueCategory
        open={isSubIssueModalOpen}
        handleClose={() => setIsSubIssueModalOpen(false)}
        issueCategoryData={selectedIssueCategory}
        refetch={refetch}
      />
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        issueId={selectedToDelete.issueId}
        subIssueId={selectedToDelete.subIssueId}
        refetch={refetch}
      />
      <DeleteIssueCategoryDialog
        open={isIssueDeleteOpen}
        onClose={() => setisIssueDeleteOpen(false)}
        issueId={selectedissue}
        refetch={refetch}
      />
      <FullpageLoader open={isLoading || isRefetching} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Tooltip title="Add New Issue Category">
          <IconButton onClick={handleAddIssueCategoryModalOpen}>
            <AddCircleOutlineOutlined
              sx={{ fontSize: "35px", color: "#1F4172" }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      {cardDataChunks?.map((chunk, chunkIndex) => (
        <Box
          key={chunkIndex}
          marginTop={chunkIndex === 0 ? "1rem" : "2rem"}
          marginBottom="1rem"
          display="flex"
          flexDirection="row"
          gap="4rem"
        >
          {chunk?.map((data, index) => (
            <Card key={index} sx={{ width: "30%", height: "350px" }}>
              <CardContent
                sx={{
                  height: "340px",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#888 #f1f1f1",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                  overflowY: "auto",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" fontSize="18px">
                    {data?.name}
                  </Typography>
                  <Box>
                    <Tooltip title="Add sub issue category">
                      <IconButton
                        onClick={() =>
                          handleAddSubIssueCategoryModalOpen(
                            data?._id,
                            data?.name
                          )
                        }
                      >
                        <AddCircleOutlineOutlined
                          sx={{ fontSize: "25px", color: "#1F4172" }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete issue-category">
                      <IconButton
                        onClick={() => {
                          setSelectedIssue(data._id);
                          setisIssueDeleteOpen(true);
                        }}
                      >
                        <DeleteOutline sx={{ color: "#FF6969" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>

                <Divider sx={{ marginTop: "0.5rem" }} />
                <Typography variant="caption" color="#607274" fontSize="12px">
                  Sub-Issue
                </Typography>
                {data?.sub_issues?.map((subissue, subIndex) => (
                  <>
                    {" "}
                    <Box
                      key={subIndex}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Typography
                        variant="span"
                        fontWeight="medium"
                        fontSize="15px"
                      >
                        {subissue?.name}
                      </Typography>
                      <Tooltip title="Delete Subissue">
                        <IconButton
                          onClick={() => {
                            setSelectedToDelete({
                              issueId: data._id,
                              subIssueId: subissue._id,
                            });
                            setIsDeleteDialogOpen(true);
                          }}
                        >
                          <DeleteOutline sx={{ color: "#FF6969" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Divider sx={{ marginTop: "0.5rem" }} />
                  </>
                ))}
              </CardContent>
            </Card>
          ))}
        </Box>
      ))}
    </>
  );
};

export default GetIssueCategory;

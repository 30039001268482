import agentViewLeeds from "app/pages/outbundCampaign/agentViewLeeds";
import createCampaign from "app/pages/outbundCampaign/createCampaign";

const agentoutboundCampaignRoutes = [
  {
    path: "/app/agent/outbound-campaign",
    shortPath: "/agent/outbound-campaign",
    name: "View Campaign",
    component: agentViewLeeds,
    icon: "phone-plus",
    auth: ["agent", "agent"],
  },
];
export default agentoutboundCampaignRoutes;

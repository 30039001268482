import AgentTicketReportPage from "app/pages/ticket/AgentTicketReportPage";
import { lazy } from "react";

// Ticket

const CreateTicketNew = lazy(() => import("app/pages/ticket/createNew"));
const AssignedToMe = lazy(() => import("app/pages/assignedToMe"));

const agentReportTicket = [
  {
    path: "/app/call-ticket",
    shortPath: "/ticket/ticket-list",
    name: " My Tickets",
    icon: "mdi mdi-clipboard-list-outline",
    component: AgentTicketReportPage,
  },
  {
    path: "/app/assined-to-me",
    shortPath: "/ticket/assined-to-me",
    name: "Assigned to me",
    icon: "mdi mdi-clipboard-list-outline",
    component: AssignedToMe,
  },
  {
    path: "/app/create-ticket",
    shortPath: "/ticket/create-ticket",
    name: "Create Ticket",
    icon: "mdi mdi-clipboard-list-outline",
    component: CreateTicketNew,
    hideInSidebar: true,
  },
];

export default agentReportTicket;

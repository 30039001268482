import {
  Person,
  Badge,
  PendingActions,
  CheckCircle,
  Grid3x3,
  Upload,
} from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  TextField,
  Button,
  FormControl,
} from "@mui/material";
import SelectComponent from "app/components/Shared/Select/Select.component";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: 8,
};

const EditUserModal = ({ open, handleeditClose, data }) => {
  const RoleOptions = [
    {
      id: 1,
      value: "agent",
      label: "Agent",
    },
    {
      id: 2,
      value: "admin",
      label: "Admin",
    },
  ];
  return (
    <>
      <Modal
        open={open}
        onClose={handleeditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl>
            <Box display="flex" flexDirection="row" gap="20px">
              <Box
                display="flex"
                flex-wrap="wrap"
                flexDirection="column"
                gap="20px"
              >
                <h2 id="modal-modal-title">Edit New User</h2>
                {/* //Full name */}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  id="modal-modal-description"
                  gap="20px"
                >
                  <TextField
                    label="First Name "
                    size="small"
                    sx={{ width: "20ch" }}
                    autoComplete="off"
                  />
                  <TextField
                    label="Middle Name "
                    size="small"
                    sx={{ width: "20ch" }}
                    autoComplete="off"
                  />
                  <TextField
                    label="Last Name "
                    size="small"
                    sx={{ width: "20ch" }}
                    autoComplete="off"
                  />
                </Box>

                <TextField label="Email ID" size="small" autoComplete="off" />
                <TextField
                  label="Mobile Number "
                  size="small"
                  autoComplete="off"
                />
                <TextField label="Password" size="small" autoComplete="off" />

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  id="modal-modal-description"
                  gap="20px"
                >
                  <TextField
                    label="Extension Number "
                    size="small"
                    autoComplete="off"
                  />
                  <Box width="15em">
                    <SelectComponent
                      options={RoleOptions}
                      label={"Select Role"}
                      name={"role"}
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginRight="25px"
                >
                  <Button size="medium" variant="contained">
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

export default EditUserModal;

// Importing the EventEmitter class from the "events" module.
// EventEmitter provides a way to handle custom events within an application.
import EventEmitter from "events";

// Creating a new instance of the EventEmitter.
// This instance will allow different parts of the application
// to subscribe to events (with `on` or `addListener`) and emit events (with `emit`).
const eventEmitter = new EventEmitter();

// Exporting the created eventEmitter instance.
// By exporting this instance, other parts of the application can import it
// and use it to coordinate and communicate through events. This ensures that
// there's a single shared event emitter for the entire application.
export default eventEmitter;

import { SupportAgent } from "@mui/icons-material";
import { Box, Button, Checkbox } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getRegisteredClients } from "app/utils/api-urls/AdminAPiUrls";

const LeedTableData = ({ registeredClientData, setSelectedRows }) => {
  const [selected, setSelected] = useState([]);

  const handleSelect = (id) => {
    const currentIndex = selected.indexOf(id);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
    setSelectedRows(newSelected); // Update the selected lead IDs in the parent
  };

  return (
    <>
      <Box
        sx={{
          marginLeft: "3rem",

          marginTop: "2rem",
          height: "40vh",
          width: "95%",
          marginBottom: "2rem",
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  padding="checkbox"
                  sx={{ backgroundColor: "#ECF2FF", color: "#fff" }}
                >
                  <Checkbox
                    checked={selected?.length === registeredClientData?.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        const allIds = registeredClientData?.map((n) => n._id);
                        setSelected(allIds);
                        setSelectedRows(allIds); // Update the selected lead IDs in the parent
                        return;
                      }
                      setSelected([]);
                      setSelectedRows([]); // Update the selected lead IDs in the parent
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#ECF2FF", color: "#272727" }}
                >
                  Customer Name
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#ECF2FF", color: "#272727" }}
                >
                  Contact
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#ECF2FF", color: "#272727" }}
                >
                  Extension
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {registeredClientData?.map((item) => (
                <TableRow key={item._id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.indexOf(item._id) !== -1}
                      onChange={() => handleSelect(item._id)}
                    />
                  </TableCell>
                  <TableCell>
                    {
                      item.fields.find(
                        (field) => field.field.key === "Customer Name"
                      )?.value
                    }
                  </TableCell>
                  <TableCell>
                    {
                      item.fields.find((field) => field.field.key === "Contact")
                        ?.value
                    }
                  </TableCell>
                  <TableCell>
                    {" "}
                    {item?.extension
                      ? item?.extension?.extension
                      : "Not Assigned"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default LeedTableData;

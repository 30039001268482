import { Typography, Box } from "@mui/material";
import React, { useEffect,useRef  } from "react";
import { useState } from "react";
import $ from "jquery"

const WebRtcStatus = () => {
  const [data, setData] = useState([]);

  // const SipStatiusdata = localStorage.getItem("SipStatus");
  

  // useEffect(() => {
  //   setData(SipStatiusdata);
  // });

  // useEffect(() => {
  //   const myInputRef = inputRef.current;
  //   myInputRef.onchange = e => setData(e.target.value)
  // }, [])

  return (
    <Box
      margin="0 50px"
      variant="span"
      fontSize="17px"
      borderRadius="10px"
      display="flex"
      alignItems="center"
      color="#232C93"
      flexDirection="row"
      gap="10px"
    >
      <Typography> Connection Status: </Typography>

      <Typography variant="button" fontSize="16px" fontWeight="500">
        <div>
          <i id="WebRtcStatus">Connecting...</i>
        </div>
      </Typography>
    </Box>
  );
};

export default WebRtcStatus;

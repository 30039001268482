import { TicketUidActions } from "./TicketUidActionTypes";

const INITIAL_STATE = {
  current_uuid: null,
};

export const TicketUidReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case TicketUidActions.GET_CURRENT_UUID:
      return {
        ...state,
        current_uuid: payload,
      };

    default:
      return state;
  }
};

import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { Box, Typography } from "@mui/material";

const HourlyReportTable = ({ isLoading, tableData }) => {
  const columns = [
    {
      field: "time",
      headerName: "Time Period",
      width: 250,
    },

    {
      field: "entered",
      headerName: "Entered",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Box
              display="flex"
              alignItems="center"
              gap="5px"
              backgroundColor="#579BB1"
              padding="2px"
              color="#fff"
              borderRadius="10px"
              height="2em"
              width="7em"
              justifyContent="center"
            >
              <>
                <Typography fontSize="15px" variant="span">
                  {params.row.entered} calls
                </Typography>
              </>
            </Box>
          </>
        );
      },
    },
    {
      field: "answered",
      headerName: "Answered",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <Box
              display="flex"
              alignItems="center"
              gap="5px"
              backgroundColor="#59981A"
              padding="2px"
              color="#fff"
              borderRadius="10px"
              height="2em"
              width="7em"
              justifyContent="center"
            >
              <>
                <Typography fontSize="15px" variant="span">
                  {params.row.answered} calls
                </Typography>
              </>
            </Box>
          </>
        );
      },
    },
    {
      field: "abandoned",
      headerName: "Abandoned",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <Box
              display="flex"
              alignItems="center"
              gap="5px"
              backgroundColor="#EF7C8E"
              padding="2px"
              color="#fff"
              borderRadius="10px"
              height="2em"
              width="7em"
              justifyContent="center"
            >
              <>
                <Typography fontSize="15px" variant="span">
                  {params.row.abandoned} calls
                </Typography>
              </>
            </Box>
          </>
        );
      },
    },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     Queue: "KYC",
  //     createdAt: "2022-11-15",
  //     TimePeriod: "10:00:00 - 11:00:00",
  //     Entered: "30",
  //     Answered: "29",
  //     Abandoned: "1",
  //   },
  //   {
  //     id: 2,
  //     Queue: "Payment",
  //     createdAt: "2022-11-15",
  //     TimePeriod: "10:00:00 - 11:00:00",
  //     Entered: "30",
  //     Answered: "29",
  //     Abandoned: "1",
  //   },
  //   {
  //     id: 3,
  //     Queue: "Billing",
  //     createdAt: "2022-11-15",
  //     TimePeriod: "10:00:00 - 11:00:00",
  //     Entered: "30",
  //     Answered: "29",
  //     Abandoned: "1",
  //   },
  //   {
  //     id: 4,
  //     Queue: "KYC",
  //     createdAt: "2022-11-15",
  //     TimePeriod: "10:00:00 - 11:00:00",
  //     Entered: "30",
  //     Answered: "29",
  //     Abandoned: "1",
  //   },
  //   {
  //     id: 5,
  //     Queue: "KYC",
  //     createdAt: "2022-11-15",
  //     TimePeriod: "10:00:00 - 11:00:00",
  //     Entered: "30",
  //     Answered: "29",
  //     Abandoned: "1",
  //   },
  // ];

  return (
    <Box width="100%" height="62vh">
      {!isLoading && tableData && (
        <>
          <DataGrid
            columns={columns}
            rows={tableData}
            getRowId={(rows) => rows["time"]}
            hideFooter="true"
          />
        </>
      )}
    </Box>
  );
};

export default HourlyReportTable;

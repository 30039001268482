import{
    FETCHED_FAQ
} from './faqActionTypes';

const init = {
    allFaq :[],
    paginateData:''
}

const faqReducer = (state = init, action) =>{
    const {type, payload} = action;

    switch (type) {
        case FETCHED_FAQ:
            return{
                ...state,
                allFaq: payload.FAQs,
                paginateData: payload.pagination.pages
            }
        default:
            return state;
    }

} 

export default faqReducer;
import { lazy } from "react";

// setup
//TODO
//TODO
const setupRoutes = [
  // {
  //   path: "/app/setup/user",
  //   shortPath: "/setup/user",
  //   name: "Users",
  //   component: SetupUser,
  //   icon: "mdi mdi-account",
  // },
  // {
  //   path: "/app/setup/modules",
  //   shortPath: "/setup/modules",
  //   name: "Modules",
  //   component: SetupUser,
  //   icon: "mdi mdi-cube-unfolded",
  // },
  // {
  //   path: "/app/setup/roles",
  //   shortPath: "/setup/roles",
  //   name: "Roles",
  //   component: RoleList,
  //   icon: "mdi mdi-cube-unfolded",
  // },
  // {
  //   path: "/app/setup/permissions",
  //   shortPath: "/setup/permissions",
  //   name: "Permissions",
  //   component: Permissions,
  //   icon: "mdi mdi-nature-people",
  // },
  // {
  //   path: "/app/setup/setup-permissions",
  //   shortPath: "/setup/setup-permissions",
  //   name: "Setup permissions",
  //   component: CreatePermissions,
  //   icon: "mdi mdi-nature-people",
  // },
];

export default setupRoutes;

import React from 'react'
import SelectComponent from '../Shared/Select/Select.component';
import { Box} from "@mui/material";

const AgentTableFilter=({
     filterData,
    handleChange
}) =>
    {
     

      const statusOptions = [
        {
          id: 1,
          value: "inbound",
          label: "Inbound",
        },
        {
          id: 2,
          value: "inbound-missed",
          label: "Inbound Missed",
        },

        {
          id: 3,
          value: "inbound-success",
          label: "Inbound Success",
        },
        {
          id: 4,
          value: "outbound",
          label: "Outbound",
        },
        {
          id: 5,
          value: "outbound-missed",
          label: "Outbound Missed",
        },

        {
          id: 6,
          value: "outbound-success",
          label: "Outbound Success",
        },
        {
          id: 7,
          value: "internal",
          label: "Internal",
        },
      ];

      return (
        <Box width="10em">
          <SelectComponent
            options={statusOptions}
            value={filterData.type}
            handleChange={handleChange}
            label={"CallType"}
            name={"type"}
          />
        </Box>
      );
    }

export default AgentTableFilter
import React, { useState } from "react";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import { Dialpad } from "@material-ui/icons";
import { Box, ClickAwayListener, IconButton, Typography } from "@mui/material";
import { Call, Cancel } from "@mui/icons-material";

const DialpadComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleButtonClick = (digit) => {
    setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + digit);
  };
  s;

  const handleDeleteClick = () => {
    setPhoneNumber((prevNumber) => prevNumber.slice(0, -1));
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      position: "fixed",
      bottom: "20px",
      right: "200px",
      zIndex: "1000",
      backgroundColor: "white",
      border: "1px solid gray",
      borderRadius: "5px",
      padding: "10px",
      [theme.breakpoints.down("sm")]: {
        right: "20px",
      },
    },
    callButton: {
      width: "66px",
      height: "66px",
      fontSize: "30px",
      fontWeight: 700,
      borderRadius: "50%",
      border: "1px solid #d0d0d0",
      backgroundColor: "#5BC236",

      margin: "5px",
      boxShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.1)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "50px",
        fontSize: "20px",
      },
    },
    button: {
      marginRight: "10px",
      marginTop: "10px",
    },
    dialPadContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "20px",
    },
    dialPadRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dialPadButton: {
      width: "70px",
      height: "70px",
      fontSize: "26px",
      fontWeight: "bold",
      borderRadius: "50%",
      border: "1px solid #d0d0d0",
      backgroundColor: "#f8f8f8",
      margin: "5px",
      boxShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.1)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "50px",
        fontSize: "20px",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isOpen ? (
        <>
          {/* textfield and call button */}
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <TextField
                label="Phone Number"
                variant="standard"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item>
              <IconButton
                className={classes.iconButton}
                onClick={handleDeleteClick}
              >
                <Cancel />
              </IconButton>
            </Grid>
          </Grid>

          <div className={classes.dialPadContainer}>
            <div className={classes.dialPadRow}>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("1")}
              >
                1
              </Button>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("2")}
              >
                2
              </Button>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("3")}
              >
                3
              </Button>
            </div>
            <div className={classes.dialPadRow}>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("4")}
              >
                4
              </Button>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("5")}
              >
                5
              </Button>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("6")}
              >
                6
              </Button>
            </div>
            <div className={classes.dialPadRow}>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("7")}
              >
                7
              </Button>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("8")}
              >
                8
              </Button>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("9")}
              >
                9
              </Button>
            </div>
            <div className={classes.dialPadRow}>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("*")}
              >
                *
              </Button>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("0")}
              >
                0
              </Button>
              <Button
                className={classes.dialPadButton}
                onClick={() => handleButtonClick("#")}
              >
                #
              </Button>
            </div>
            <div className={classes.dialPadRow}>
              <IconButton className={`${classes.button} ${classes.callButton}`}>
                <Call />
              </IconButton>
            </div>
          </div>
        </>
      ) : (
        <IconButton color="primary" onClick={() => setIsOpen(true)}>
          <Dialpad />
        </IconButton>
      )}
    </div>
  );
};

export default DialpadComponent;

import { breakCategory } from "app/utils/api-urls/AdminAPiUrls";
import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";

const DeleteBreakCategory = ({ open, refetch, onClose, breakCategoryId }) => {
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const { mutate: deleteBreakCategoy, isLoading } = useMutation(
    ["delete-break-category"],
    () => instance.delete(`${breakCategory.break_category}/${breakCategoryId}`),
    {
      onSuccess: () => {
        refetch();
        onClose();
        setSnackBar({
          open: true,
          severity: "success",
          message: "Break Deleted successfully!",
        });
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Break deleting template!",
        });
      },
    }
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Break Category?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Break Category? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => deleteBreakCategoy()}
            color="primary"
            autoFocus
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
      >
        <Alert
          onClose={() => setSnackBar({ ...snackBar, open: false })}
          severity={snackBar.severity}
          sx={{ width: "100%" }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DeleteBreakCategory;

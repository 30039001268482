import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import ConversationList from "./ConversationList";
import MainChatBox from "./MainChatBox";
import { chatbotsocketURL, chatinstance } from "app/utils/axiosRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import socketIOClient from "socket.io-client";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5856D6",
    },
    secondary: {
      main: "#3F51B5",
    },
  },
});

const ChatboxSection = () => {
  const [message, setMessage] = useState({
    message: "",
    receiverId: "",
    type: "",
    messageType: "text",
  });

  const [conversationList, setConversationList] = useState([]);
  const [selectedRecipent, setSelectedRecipent] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState([]);

  const handleMessageChange = (e) => {
    const inputValue = e.target.value || "";
    setMessage((prevData) => ({
      ...prevData,
      message: inputValue,
    }));
  };

  const formatDate = (date) => {
    return dayjs(date).format("MMMM DD, YYYY");
  };

  const formatTime = (date) => {
    return dayjs(date).format("hh:mm A");
  };

  //api fetch for all user

  const { isLoading, data, error, refetch } = useQuery(["allchatuser"], () =>
    chatinstance.get(`/admin/allUsers`)
  );

  //api fetch for message when clicked
  const urlPath = `/admin/user/messages?userId=${selectedRecipent}`;

  const { data: Data, refetch: refetchSelectedConversation } = useQuery(
    ["ViewAllMessage"],
    () => chatinstance.get(urlPath)
  );

  //useEffect to set conversation list and selected conversation

  //function call when conversation list is clicked
  const handleConversationlistClicked = (conversation, type) => {
    setSelectedRecipent(conversation);

    selectedRecipent && refetchSelectedConversation();
    setMessage((prevMessage) => ({
      ...prevMessage,
      receiverId: conversation,
      type: type,
    }));
  };

  useEffect(() => {
    data && setConversationList(data?.data);
    refetchSelectedConversation && setSelectedConversation(Data?.data);
  }, [data, Data]);

  //useEffect when users select reciepent

  useEffect(() => {
    if (selectedRecipent) {
      const urlPath = `/admin/${selectedRecipent}/messages`;
      refetchSelectedConversation(urlPath);
    }
  }, [selectedRecipent, refetchSelectedConversation]);

  //api to send a message

  const { mutate: sendMessageMutate, isSuccess } = useMutation(
    ["SendMessage", message],
    () => chatinstance.post("/admin/sendReply", message),
    {
      onSuccess: () => {
        setMessage((prevData) => ({
          ...prevData,
          message: "",
        }));
      },
    }
  );

  //function handler to send a message
  const handleSendMessage = () => {
    sendMessageMutate();
    refetchSelectedConversation();
  };

  //to keep latest conversation list on top

  const updateConversationList = (latestMessage) => {
    setConversationList((prevConversationList) => {
      const updatedList = [...prevConversationList];
      const conversationIndex = updatedList.findIndex(
        (conversation) => conversation.senderId === latestMessage.userId
      );

      if (conversationIndex !== -1) {
        const updatedConversation = {
          ...updatedList[conversationIndex],
          latestMessage: latestMessage.latestMessage,
        };

        updatedList.splice(conversationIndex, 1);
        updatedList.unshift(updatedConversation);
      }

      return updatedList;
    });
  };

  //socket implementation
  useEffect(() => {
    const authToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(
      "https://test-ciomni.waterflowtechnology.net/",
      {
        extraHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    socket.on(`message@${selectedRecipent}`, (newMessage) => {
      setSelectedConversation((prevConversation) => [
        ...prevConversation,
        newMessage,
      ]);
    });

    socket.on("latestMessage", (latestmessage) => {
      updateConversationList(latestmessage);
    });
  }, [selectedRecipent]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          height: "75vh",
        }}
      >
        {/* Conversation List */}
        <ConversationList
          conversationList={conversationList}
          handleConversationlistClicked={handleConversationlistClicked}
          selectedConversation={selectedConversation}
          selectedRecipent={selectedRecipent}
        />

        {/* Main Chat Box */}
        <MainChatBox
          selectedConversation={selectedConversation}
          handleSendMessage={handleSendMessage}
          formatDate={formatDate}
          formatTime={formatTime}
          handleMessageChange={handleMessageChange}
          message={message}
          selectedRecipent={selectedRecipent}
        />
      </Box>
    </ThemeProvider>
  );
};

export default ChatboxSection;

import {
  ACTIVATE_SMALL_LOADER,
  DEACTIVATE_SMALL_LOADER
} from './loaderActionTypes'

export const smallLoaderReducer = (state = { flag: false }, action) => {
    const {type} = action;
  switch (type) {
    case ACTIVATE_SMALL_LOADER:
      return { flag: true }
    case DEACTIVATE_SMALL_LOADER:
      return { flag: false }
    default:
      return state
  }
}

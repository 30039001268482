import { CalendarMonth, Description, LibraryAdd } from "@mui/icons-material";
import {
  Box,
  TextField,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";

import TableFieldComponent from "./TableFieldComponent";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";

const CreateCampaignComponent = ({
  registerCampaignData,
  handleToDate,
  handleFromDate,
  handleTextChange,
  handleDescriptionChange,
  tableField,
  FieldTypeOptions,
  handleAddTableField,
  handleDeleteTableField,
  handleTableFieldChange,
  handleSubmit,
}) => {
  return (
    <>
      <Box
        justifyContent="flex-start"
        marginLeft="12rem"
        display="flex"
        flexDirection="column"
      >
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            gap: "7rem",
          }}
        >
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography color="#232C93" fontSize="20px" variant="span">
              <LibraryAdd sx={{ marginRight: "8px" }} />
              Create a new campaign
            </Typography>
            <TextField
              name="name"
              value={registerCampaignData?.campaignName}
              onChange={handleTextChange}
              size="small"
              fullWidth
              sx={{ width: "250px" }}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap="10px">
            <Typography color="#232C93" fontSize="20px" variant="span">
              <CalendarMonth sx={{ marginRight: "8px" }} />
              From
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <DatePicker
                value={registerCampaignData?.startDate}
                handleChange={handleFromDate}
                name="from"
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography color="#232C93" fontSize="20px" variant="span">
              <CalendarMonth sx={{ marginRight: "8px" }} />
              To
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <DatePicker
                value={registerCampaignData?.endDate}
                handleChange={handleToDate}
                name="to"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography color="#232C93" fontSize="20px" variant="span">
            <Description sx={{ marginRight: "8px" }} />
            Descriptions *
          </Typography>
          <TextField
            name="description"
            value={registerCampaignData?.description}
            onChange={handleDescriptionChange}
            size="large"
            multiline
            rows={3}
            required
            sx={{ width: "450px" }}
          />
        </Box>
        <TableFieldComponent
          FieldTypeOptions={FieldTypeOptions}
          tableField={tableField}
          handleAddTableField={handleAddTableField}
          handleDeleteTableField={handleDeleteTableField}
          handleTableFieldChange={handleTableFieldChange}
        />
        <Box
          display="flex"
          marginRight="4rem"
          marginBottom="10px"
          justifyContent="flex-end"
        >
          <Button onClick={handleSubmit} variant="contained">
            Save and continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CreateCampaignComponent;

import React from "react";

import { TextField } from "@mui/material";

const TextfieldComponent = ({
  label,
  handlechange,
  value,
  name,
  ...otherProps
}) => {
  return (
    <>
      <TextField
        id="outlined-basic"
        label={label}
        value={value}
        name={name}
        variant="outlined"
        size="small"
        onChange={handlechange}
        {...otherProps}
      />
    </>
  );
};

export default TextfieldComponent;

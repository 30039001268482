import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
  CircularProgress,
  LinearProgress,
  Tabs,
  Tab,
} from "@mui/material";
import PropTypes from "prop-types";

import { CallMade, CallMadeOutlined, CallReceived } from "@mui/icons-material";
import InboundCall from "./Call Reports/InboundCall";
import OutboundCall from "./Call Reports/OutboundCall";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CallReportsSection = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid item xs={12} md={6}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
            >
              <Typography variant="span" fontSize="18px">
                Call Reports
              </Typography>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Box display="flex" height="2rem" justifyContent="flex-end">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Inbound" {...a11yProps(0)} />
                  <Tab label="Outbound" {...a11yProps(1)} />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <InboundCall />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <OutboundCall />
              </TabPanel>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default CallReportsSection;

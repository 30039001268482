import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import dayjs from "dayjs";
import React from "react";

const BreakDetailsTable = ({
  isLoading,
  tableData,
  handleLeftClick,
  handleRightClick,
}) => {
  const columns = [
    {
      field: "reason",
      headerName: "Break Category",
      width: 250,
    },
    {
      field: "extension",
      headerName: "Agent",
      width: 100,
    },

    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      renderCell: (params) => {
        return dayjs(params?.row?.createdAt).format("YYYY-MM-DD");
      },
    },
    {
      field: "breakInTime",
      headerName: "Start Time",
      width: 150,
      renderCell: (params) => {
        return dayjs(params?.row?.breakInTime).format("H:mm:ss");
      },
    },
    {
      field: "breakOutTime",
      headerName: "End Time",
      width: 150,
      renderCell: (params) => {
        return dayjs(params?.row?.breakOutTime).format("H:mm:ss");
      },
    },
    {
      field: "totalTime",
      headerName: " Total Time",
      width: 150,
    },
  ];

  const rows = [
    {
      id: 1,
      breakcategory: "Tea Break",
      contact: "9808080858",
      agentName: "Praful Thapa",
      agentExtension: "2000",
      date: "05 Sep 2022 ",
      starttime: "10:45:00",
      endtime: "10:50:00",
      totaltime: "00:05:00",
    },
    {
      id: 2,
      breakcategory: "Restroom Break",
      contact: "9808080858",
      agentName: "Praful Thapa",
      agentExtension: "2000",
      date: "05 Sep 2022 ",
      starttime: "10:45:00",
      endtime: "10:50:00",
      totaltime: "00:05:00",
    },
    {
      id: 3,
      breakcategory: "Lunch Break",
      contact: "9808080858",
      agentName: "Praful Thapa",
      agentExtension: "2000",
      date: "05 Sep 2022 ",
      starttime: "10:45:00",
      endtime: "10:50:00",
      totaltime: "00:05:00",
    },
    {
      id: 4,
      breakcategory: "Meeting Break",
      contact: "9808080858",
      agentName: "Praful Thapa",
      agentExtension: "2000",
      date: "05 Sep 2022 ",
      starttime: "10:45:00",
      endtime: "10:50:00",
      totaltime: "00:05:00",
    },
    {
      id: 5,
      breakcategory: "Ticket Closing Break",
      contact: "9808080858",
      agentName: "Praful Thapa",
      agentExtension: "2000",
      date: "05 Sep 2022 ",
      starttime: "10:45:00",
      endtime: "10:50:00",
      totaltime: "00:05:00",
    },
    {
      id: 6,
      breakcategory: "Ticket Closing Break",
      contact: "9808080858",
      agentName: "Praful Thapa",
      agentExtension: "2001",
      date: "05 Sep 2022 ",
      starttime: "10:45:00",
      endtime: "10:50:00",
      totaltime: "00:05:00",
    },
  ];
  return (
    <Box width="100%" height="62vh">
      {!isLoading && tableData && (
        <>
          <DataGrid
            columns={columns}
            rows={tableData?.data}
            getRowId={(rows) => rows["breakInTime"]}
            hideFooter="true"
          />
          <CustomPagination
            currentPage={tableData?.currentPage}
            totalPage={tableData?.totalPages}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
          />
        </>
      )}
    </Box>
  );
};

export default BreakDetailsTable;

import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";

const ConversationList = ({
  conversationList,
  handleConversationlistClicked,
  selectedRecipent,
}) => {
  return (
    <>
      <Box
        sx={{
          flex: 1,
          backgroundColor: "#FAFAFA",
          padding: "20px",
          borderRight: "1px solid #ccc",
          overflowY: "auto",
        }}
      >
        <List sx={{ padding: 0 }}>
          {conversationList.map((conversation, index) => (
            <ListItem
              key={index}
              button
              alignItems="flex-start"
              sx={{
                padding: "10px 0",
                borderBottom: "1px solid #ccc",
                backgroundColor:
                  selectedRecipent === conversation.senderId &&
                  conversation.type === "viber"
                    ? "#B799FF"
                    : selectedRecipent === conversation.senderId &&
                      conversation.type === "telegram"
                    ? "#2AABEE"
                    : selectedRecipent === conversation.senderId &&
                      conversation.type === "whatsapp"
                    ? "#25D366"
                    : selectedRecipent === conversation.senderId
                    ? "#006AFF"
                    : "",
                borderRadius: "8px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleConversationlistClicked(
                  conversation?.senderId,
                  conversation?.type
                )
              }
            >
              <Avatar src="/path/to/avatar.jpg" alt="" />
              <ListItemText
                primary={conversation?.name}
                secondary={conversation?.latestMessage?.message}
                sx={{
                  fontFamily: "bold",
                  fontSize: "23px",
                  marginLeft: "10px",
                }}
              />

              {conversation?.type === "viber" && (
                <Avatar
                  alt="customer image"
                  src="/viber_icon.svg"
                  sx={{ marginRight: "6px", height: "30px", width: "30px" }}
                />
              )}
              {conversation?.type === "telegram" && (
                <Avatar
                  alt="customer image"
                  src="/telegram.svg"
                  sx={{ marginRight: "6px", height: "30px", width: "30px" }}
                />
              )}
              {conversation?.type === "messenger" && (
                <Avatar
                  alt="customer image"
                  src="/messenger.svg"
                  sx={{ marginRight: "6px", height: "30px", width: "30px" }}
                />
              )}
              {conversation?.type === "whatsapp" && (
                <Avatar
                  alt="customer image"
                  src="/whatsap.svg"
                  sx={{ marginRight: "6px", height: "40px", width: "40px" }}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default ConversationList;

import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useFormControl } from "@mui/material/FormControl";
import { width } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import Input from "@mui/material/Input";
import Upload from "@mui/icons-material/Upload";

const Myprofile = () => {
  const ariaLabel = { "aria-label": "description" };
  const styles = {
    // display:"flex"
    //  justifyContent:"center"
    //   marginTop:"1em
  };
  return (
    <>
      <Box>
        <Card sx={{ height: "75vh", marginTop: "9px", display: "flex" }}>
          <CardContent>
            <Box display="flex" flexDirection="row" gap="20px">
              <Box
                sx={{
                  m: 8,
                  ml: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Avatar
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 100, height: 100 }}
                />

                <Box display="flex" flexDirection="column" sx={{ ml: -3 }}>
                  <Typography variant="span" fontWeight="bold">
                    uzalgunner@gmail.com
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Card sx={{ height: "72vh", width: 600 }}>
                  <CardContent>
                    <Box
                      display="flex"
                      flex-wrap="wrap"
                      flexDirection="column"
                      gap="20px"
                    >
                      <Typography variant="span" fontSize="20px">
                        Profile Settings
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <TextField
                          label="First Name "
                          size="small"
                          sx={{ width: "35ch" }}
                          autoComplete="off"
                        />
                        <TextField
                          label="Last Name "
                          size="small"
                          sx={{ width: "35ch" }}
                          autoComplete="off"
                        />
                      </Box>

                      <TextField
                        label="Email ID"
                        size="small"
                        autoComplete="off"
                      />
                      <TextField
                        label="Mobile Number "
                        size="small"
                        autoComplete="off"
                      />
                      <TextField
                        label="Address Line 1 "
                        size="small"
                        autoComplete="off"
                      />
                      <TextField
                        label="Address Line 2 "
                        size="small"
                        autoComplete="off"
                      />
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{ width: "30ch" }}
                        startIcon={<Upload />}
                      >
                        Upload Profile Picture
                      </Button>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        marginRight="25px"
                      >
                        <Button size="medium" variant="contained">
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Myprofile;

import axios from "axios";
// import { Config } from "Config";
import { removeEmptyFields } from "./index";
import store from "app/store";
import socketIOClient from "socket.io-client";

//For Socket
// export const socketURL = {
//   liveUrl: "https://ci-backend.naasasecurities.com.np/",

// };

//for production
//For backend endpoint entry

// const newBaseURL = "https://ci-backend.naasasecurities.com.np/api/v1/";
// export const instance = axios.create({
//   baseURL: newBaseURL,
//   timeout: 30000,
// });

//For development

export const socketURL = {
  liveUrl: process.env.REACT_APP_SOCKET_URL,
};

const accessToken = localStorage.getItem("callCenterToken");

// export const socketURL = socketIOClient(
//   "https://connect-infinity-development.waterflowtechnology.net/",
//   {
//     extraHeaders: {
//       Authorization: `Bearer ${accessToken}`,
//     },
//   }
// );

const newBaseURL = process.env.REACT_APP_API_URL;
export const instance = axios.create({
  baseURL: newBaseURL,
  timeout: 30000,
});

instance.interceptors.request.use(
  async (req) => {
    const token = localStorage.getItem("callCenterToken");
    let urls = req.url.split("/");
    const endpoint = urls[urls.length - 1];

    if (token != null && endpoint !== "login") {
      req.headers.Authorization = `Bearer ${token}`;
    }
    if (!req?.runInBackground && !req?.data?.runInBackground) {
      store.dispatch({ type: "ACTIVATE_LOADING" });
    } else {
      store.dispatch({ type: "INCREASE_LOADING_COUNT" });
    }

    //For get
    if (req.params) {
      req.params = removeEmptyFields(req.params);
    }
    return req;
  },
  function (err) {
    return Promise.reject(err);
  }
);

///  For chatbot url and instance

const chatbotUrl = process.env.REACT_APP_CHATBOT_URL;

export const chatinstance = axios.create({
  baseURL: chatbotUrl,
  timeout: 30000,
});

chatinstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

//for outbound campaign url

const outboundCampaignURL =
  "https://connect-infinity-development.waterflowtechnology.net/";

export const outboundCampaign = axios.create({
  baseURL: outboundCampaignURL,
  timeout: 30000,
});

//for chitchat
const chatticketingurl = process.env.REACT_APP_CHAT_TICKETING_URL;

export const chatticketinginstance = axios.create({
  baseURL: chatticketingurl,
  timeout: 30000,
});

chatticketinginstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

import React, { useState, useEffect } from "react";
import { Dropdown, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { routes } from "app/routes";
import { Cached, HeadsetMic } from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";
import AgentStatus from "./AgentStatus";
import BreakStatus from "app/components/Breakbutton";
import { instance, socketURL } from "app/utils/axiosRequest";
import { useQuery } from "@tanstack/react-query";
import WebRtcStatus from "./WebRtcStatus";
import Myprofile from "app/pages/AgentProfile";
import { breakButton } from "app/utils/api-urls/AgentApiUrls";
import socketIOClient from "socket.io-client";
import { SupervisorbreakButton } from "app/utils/api-urls/SupervisorAPiUrls";
import { breakCategory } from "app/utils/api-urls/AdminAPiUrls";

const Topbar = (props) => {
  const history = useHistory();

  const userRole = localStorage.getItem("role");

  const { profile } = useSelector((state) => state.authReducer);
  const [modalView, setModalView] = React.useState(false);
  const [profileModalView, setProfileModalView] = React.useState(false);

  const username = localStorage.getItem("username");
  const userExtension = JSON.parse(localStorage.getItem("callCenterUser"));
  const SipStatiusdata = localStorage.getItem("SipStatus");
  const [statusbutton, setStatusbutton] = useState({
    is_in_break: false,
    reason: "",
  });

  const handleLogoutCLick = () => {
    localStorage.clear();
    window.open(new URL(window.location)?.origin, "_self");
    // refetch();
  };

  const redirectToTicket = () => history.push("/app/ticket/create-ticket-new");
  const redirectToProfile = () => history.push("/app/my-profile");
  const checkRole = () => {
    const DB = window.localStorage;
    const role = DB.getItem("role");
    return role ?? "null";
  };

  const roleBasedUrls = {
    agent: breakButton.extension_status,
    admin: breakCategory.break_category,
    supervisor: SupervisorbreakButton.extension_status,
  };

  const extensionStatusUrl =
    roleBasedUrls[userRole] || breakButton.extension_status;

  const {
    data: data1,
    refetch: refetch1,
    fetch: fetch1,
    isRefetching: isRefetching1,
  } = useQuery(["extensionStatus"], () => instance.get(extensionStatusUrl));

  useEffect(() => {
    const accessToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(socketURL.liveUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    socket.on(`extension-status@${userExtension}`, (data) => {
      if (data) {
        setStatusbutton(data);
      }
    });

    // Cleanup the socket connection on unmount
    return () => socket.disconnect();
  }, []);

  const handleBreakStatusChange = (event) => {
    setStatusbutton({
      ...statusbutton,
      [event.target.name]: event.target.value,
    });
  };

  const handleapihit = () => {
    refetch1();
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        fixed="top"
        className="default-layout-navbar"
      >
        <Navbar.Brand>
          <Link
            className="navbar-brand brand-logo-mini"
            style={{ maxWidth: 40 }}
            to="/app/dashboard"
          >
            <Box display="flex" gap="10px" alignItems="center" color="black">
              <HeadsetMic sx={{ color: "#232C93" }} />
              Connect Infinity
              <Box
                fontSize="14px"
                color="#232C93"
                border="1px solid #232C93"
                borderRadius="4px"
                padding="2px"
                display="flex"
              >
                1.0.2
              </Box>
            </Box>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          style={{ backgroundColor: "#232C93" }}
          aria-controls="responsive-navbar-nav"
        />

        <Navbar.Collapse>
          <Nav className="mr-auto">
            {routes.map((e, index) => {
              if (e.hideInSidebar) return null;
              if (e.dropDownState) {
                return (
                  <>
                    {e.shortPath === "/admin-dashboard" ||
                    e.shortPath === "/settings" ||
                    e.shortPath === "/report" ||
                    e.shortPath === "/outbound-campaign" ||
                    e.shortPath === "/ticket" ? (
                      e.auth.includes(checkRole()) ? (
                        <NavDropdown
                          key={index}
                          title={
                            <span>
                              <i
                                className={`mdi mdi-${e.icon} mr-1 menu-icon `}
                                style={{ color: "#232C93" }}
                              ></i>
                              {e.name}
                            </span>
                          }
                          id="collasible-nav-dropdown"
                        >
                          {e.dropDownMenu?.map((e, index) => {
                            if (e.hideInSidebar) return null;

                            return (
                              <>
                                {e.shortPath === "/ticket/assigned-to-me" ? (
                                  e.auth.includes(checkRole()) ? (
                                    <NavDropdown.Item
                                      key={index}
                                      className="py-2"
                                    >
                                      <Link
                                        to={e.path}
                                        className="link-no-color"
                                      >
                                        <i
                                          className={`mdi mdi-${e.icon} mr-2 menu-icon text-orange`}
                                        ></i>
                                        {e.name}
                                      </Link>
                                    </NavDropdown.Item>
                                  ) : null
                                ) : (
                                  <NavDropdown.Item
                                    key={index}
                                    className="py-2"
                                  >
                                    <Link to={e.path} className="link-no-color">
                                      <i
                                        className={`mdi mdi-${e.icon} mr-2 menu-icon text-orange`}
                                      ></i>
                                      {e.name}
                                    </Link>
                                  </NavDropdown.Item>
                                )}
                              </>
                            );
                          })}
                        </NavDropdown>
                      ) : null
                    ) : (
                      <NavDropdown
                        key={index}
                        title={
                          <span>
                            <i
                              style={{ color: "#232C93" }}
                              className={`mdi mdi-${e.icon} mr-1 menu-icon `}
                            ></i>
                            {e.name}
                          </span>
                        }
                        id="collasible-nav-dropdown"
                      >
                        {e.dropDownMenu?.map((e, index) => {
                          if (e.hideInSidebar) return null;
                          return (
                            <NavDropdown.Item key={index} className="py-2">
                              <Link to={e.path} className="link-no-color">
                                <i
                                  className={`mdi mdi-${e.icon} mr-2 menu-icon text-orange`}
                                ></i>
                                {e.name}
                              </Link>
                              {e?.dropDownMenuChilds?.map((a, i) => (
                                <NavDropdown.Item key={i} className="py-2">
                                  <Link to={a.path} className="link-no-color">
                                    <i
                                      className={`mdi mdi-${a.icon} mr-2 menu-icon text-orange`}
                                    ></i>
                                    {a.name}sss
                                  </Link>
                                </NavDropdown.Item>
                              ))}
                            </NavDropdown.Item>
                          );
                        })}
                      </NavDropdown>
                    )}
                  </>
                );
              }
              if (e.auth.includes(checkRole())) {
                return (
                  <Nav.Link href={e.path} key={index}>
                    <i
                      className={`mdi mdi-${e.icon} menu-icon  mr-1`}
                      style={{ color: "#232C93" }}
                    ></i>
                    <span className="menu-title ">{e.name}</span>
                  </Nav.Link>
                );
              }
            })}
          </Nav>

          <Nav.Link style={{ position: "relative" }}>
            <span className=" current-time"></span>
          </Nav.Link>

          <WebRtcStatus />
          {(userRole === "agent" || userRole === "supervisor") && (
            <Box display="flex" flexDirection="row" gap="10px">
              <Tooltip title="Press ! if the break button is not working properly">
                <Cached onClick={handleapihit} sx={{ cursor: "pointer" }} />
              </Tooltip>

              <BreakStatus
                statusbutton={statusbutton}
                handleBreakStatusChange={handleBreakStatusChange}
              />
            </Box>
          )}

          {/* <AgentStatus userExtension={userExtension} /> */}

          <Dropdown alignRight>
            <Dropdown.Toggle
              className="nav-link btn-theme"
              style={{
                boxShadow: "none",
                color: "#232C93",
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <div
                className="nav-profile-img"
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <span>{username}</span>
                <i className={`mdi mdi-account menu-icon`}></i>
                <span className="availability-status online"></span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="navbar-dropdown">
              {/* <Dropdown.Item
                href="#"
                onClick={(evt) => {
                  evt.preventDefault();
                  redirectToProfile();
                }}
              >
                <i className="mdi mdi-account mr-2 text-primary"></i>
                <Trans>My Profile</Trans>
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                href="#"
                onClick={(evt) => {
                  setModalView(true);
                  evt.preventDefault();
                }}
              >
                <i className="mdi mdi-key-variant mr-2 text-primary"></i>
                <Trans>Change Password</Trans>
              </Dropdown.Item> */}
              <Dropdown.Item
                href=""
                onClick={(evt) => {
                  evt.preventDefault();
                  handleLogoutCLick();
                }}
              >
                <i className="mdi mdi-logout mr-2 text-primary"></i>
                <Trans>LogOut</Trans>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button id="redirectToTicket" onClick={redirectToTicket} hidden>
            Redirect
          </button>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Topbar;

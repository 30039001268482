import { lazy } from "react";

// FAQ
// const FAQ = lazy(() => import("app/pages/faq"));
// const CreateFAQ = lazy(() => import("app/pages/faq/create"));

const reportRoutes = [
  // {
  //   path: "/app/faq/create",
  //   shortPath: "/faq/create",
  //   name: "Create FAQ",
  //   component: CreateFAQ,
  //   icon: "mdi mdi-comment-plus-outline",
  // },
];

// reportRoutes.push({
//   path: "/app/faq/list",
//   name: "List FAQ",
//   component: FAQ,
//   icon: "mdi mdi-comment-question",
// });

export default reportRoutes;

import {
  ArrowBack,
  FileDownload,
  FileUpload,
  SupportAgent,
} from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LeedTableData from "./LeedTableData";
import SelectAgentModal from "./SelectAgentModal";
import { saveAs } from "file-saver";
import { instance, outboundCampaign } from "app/utils/axiosRequest";
import {
  assignAgentExtension,
  getAllAgent,
  uploadLeeds,
} from "app/utils/api-urls/AdminAPiUrls";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import UploadFileModal from "./UploadFileModal";

const UploadLead = ({ handleSaveAndContinue, handleBack, uploadLeadsData }) => {
  const [modaldata, setModaldata] = useState({
    open: false,
  });

  const [uploadmodalData, setUploadmodalData] = useState({
    open: false,
  });

  const [allAgentData, setallAgentData] = useState([]);

  const [registeredClientData, setregisteredClientData] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState(null);

  const handlemodalclose = () => {
    setModaldata({
      open: false,
    });
  };

  const HandleOpenModel = () => {
    setModaldata({
      open: true,
    });
  };

  const handleOpenUploadFileModal = () => {
    setUploadmodalData({
      open: true,
    });
  };

  const handleCloseUploadFileModal = () => {
    setUploadmodalData({
      open: false,
    });
  };

  const exportToCSV = () => {
    const campaignFields = uploadLeadsData.campaignFields;

    // Prepare CSV data rows
    const csvData = [];

    // Prepare header row
    const headerRow = campaignFields.map((field) => field.key);
    csvData.push(headerRow);

    // Find the field with type "Select"
    const selectField = campaignFields.find((field) => field.type === "Select");

    // If a field with type "Select" exists
    if (selectField) {
      // Iterate over the options and add a row for each option
      selectField.options.forEach((option) => {
        const rowData = campaignFields.map((field) => {
          if (field === selectField) {
            return option;
          }
          return "";
        });
        csvData.push(rowData);
      });
    }

    // Convert data rows to CSV string
    const csvString = csvData.map((row) => row.join(",")).join("\n");

    // Create a Blob object with the CSV data
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    // Save the file using FileSaver.js
    saveAs(blob, "leads.csv");
  };

  const { isLoading, data, error, refetch } = useQuery(["agent-list"], () =>
    instance.get(getAllAgent.get_all_agent)
  );

  const { data: getRegisteredClients, refetch: refetcRegisteredClients } =
    useQuery(["registered Clients", uploadLeadsData?._id], () =>
      outboundCampaign.get(
        `outbound-campaign/client-list/${uploadLeadsData?._id}`
      )
    );

  useEffect(() => {
    data?.data && setallAgentData(data?.data);
    getRegisteredClients?.data &&
      setregisteredClientData(getRegisteredClients?.data);
  }, [data?.data, getRegisteredClients?.data]);

  const { mutate: assignAgent } = useMutation(
    ["Assign-Agents"],
    (payload) =>
      outboundCampaign.post(assignAgentExtension.assign_Agent, payload),
    {
      onSuccess: () => {
        refetcRegisteredClients();
        handlemodalclose();
      },
    }
  );

  const handleApiCall = () => {
    const payload = {
      customerId: selectedRows, // Assuming this is an array of selected row IDs
      extension: selectedAgentId,
    };
    assignAgent(payload);
  };

  return (
    <>
      <UploadFileModal
        openmodal={uploadmodalData.open}
        handleOpenUploadFileModal={handleOpenUploadFileModal}
        handleCloseUploadFileModal={handleCloseUploadFileModal}
        uploadLeadsData={uploadLeadsData}
        refetcRegisteredClients={refetcRegisteredClients}
      />
      <Box justifyContent="flex-start" display="flex" flexDirection="column">
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            flexDirection: "row",
            gap: "4rem",
            marginLeft: "2rem",
          }}
        >
          <Button onClick={handleBack} variant="text" startIcon={<ArrowBack />}>
            Back
          </Button>

          <Typography
            variant="h8"
            sx={{
              fontSize: "1.5rem",

              letterSpacing: "0.1rem",
            }}
          >
            {uploadLeadsData?.campaignName}
          </Typography>

          <Box
            sx={{
              marginLeft: "auto",
              alignItems: "center",
              gap: "2px",
              marginRight: "6rem",
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              size="small"
              startIcon={<SupportAgent />}
              sx={{ marginRight: "1rem" }}
              onClick={HandleOpenModel}
            >
              Select Agent
            </Button>

            <Button
              variant="outlined"
              size="small"
              onClick={exportToCSV}
              startIcon={<FileDownload />}
              sx={{ marginRight: "1rem" }}
            >
              Export CSV File
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleOpenUploadFileModal}
              component="label"
              startIcon={<FileUpload />}
            >
              Import CSV File
            </Button>
          </Box>
        </Box>
        {/* //TableData */}
        <LeedTableData
          setSelectedRows={setSelectedRows}
          registeredClientData={registeredClientData}
        />
        <SelectAgentModal
          handlemodalclose={handlemodalclose}
          modaldata={modaldata.open}
          agentData={allAgentData}
          setSelectedAgentId={setSelectedAgentId}
          onApiCallButtonClick={handleApiCall}
        />
        <Box
          display="flex"
          marginRight="4rem"
          marginBottom="10px"
          justifyContent="flex-end"
        >
          <Button onClick={handleSaveAndContinue} variant="contained">
            Save and continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UploadLead;
